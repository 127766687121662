import styled from 'styled-components'
import { getContrastColor } from '../../../utils/getContrastColor'

type LanguageProps = {
  isSelected?: boolean
}

export const LanguagesContainer = styled.div`
  display: flex;
  justify-content: center;

  border-radius: 6px;
  overflow: hidden;

  width: 155px;
  margin: 0 auto;
  margin-top: 30px;
`

export const Language = styled('p')<LanguageProps>`
  padding: 5px 10px;
  height: 26px;
  line-height: 16px;
  margin: 0;
  text-align: center;
  vertical-align: center;

  color: ${({ theme, isSelected = false }) =>
    isSelected ? getContrastColor(theme.colour) : theme.colour};
  background-color: ${({ theme, isSelected = false }) =>
    isSelected ? theme.colour : 'transparent'};
  font-weight: 600;

  &:not(:last-child) {
    border-right: 1px solid #fff;
  }
`
