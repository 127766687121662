import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { loadingBarReducer } from 'react-redux-loading-bar'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import authReducer from './reducers/AuthReducer'
import appReducer from './reducers/AppReducer'
import translationsReducer from './reducers/TranslationsReducer'
import commonReducer from './reducers/CommonReducer'
import categoriesReducer from './reducers/CategoriesReducer'
import productsReducer from './reducers/ProductsReducer'
import modalsReducer from './reducers/ModalsReducer'
import toasterReducer from './reducers/ToasterReducer'
import selectedProductsReducer from './reducers/SelectedProductsReducer'
import filtersReducer from './reducers/FiltersReducer'
import restaurantsReducer from './reducers/RestaurantsReducer'
import specialsReducer from './reducers/SpecialsReducer'
import wishlistSlice from './reducers/WishlistReducer'
import tableReservationReducer from './reducers/TableReservation'
// import cloudOrdersReducer from './reducers/CloudOrdersReducer'
import qrMenuTableReducer from './reducers/QRMenuTableReducer'
import qrMenuBucketReducer from './reducers/QRMenuBucketReducer'

export const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  translations: translationsReducer,
  common: commonReducer,
  categories: categoriesReducer,
  products: productsReducer,
  modals: modalsReducer,
  toasts: toasterReducer,
  selectedProducts: selectedProductsReducer,
  filters: filtersReducer,
  restaurants: restaurantsReducer,
  specials: specialsReducer,
  wishlist: wishlistSlice,
  loadingBar: loadingBarReducer,
  tableReservation: tableReservationReducer,
  // cloudOrders: cloudOrdersReducer,
  qrMenuTable: qrMenuTableReducer,
  bucket: qrMenuBucketReducer,
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['bucket'],
  version: 1,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const setupStore = () => {
  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  })
}

export const store = setupStore()
export const persistor = persistStore(store)

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
