import styled from 'styled-components'

// Products

export const ProductsList = styled.div``

export const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
  /* grid-template-columns: repeat(3, 1fr); */
  grid-gap: 10px;
`

export const AddProductContainer = styled.div`
  display: grid;
  grid-template-rows: 40% 10% 15% 15% 15%;
  justify-content: center;
  align-items: center;
  height: 100%;
  & div {
    overflow: scroll;
  }
  & span {
    text-align: center;
    line-height: 100%;
    margin: auto;
  }
`

export const AddProductPrice = styled.span`
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
`

export const Title = styled.p`
  font-weight: 600;
`
