import { useEffect, useRef, useState } from 'react'
import { MainScreenControls } from 'featureSliced/widgets/ButtonsMainScreen/Controls'
import { ButtonsContainer, MainScreenContainer } from './styled'
import { VideoBackground } from 'featureSliced/features/VideoBackground'
import { Sidebar } from 'featureSliced/widgets/Sidebar'
import { useOpenModal } from 'hooks/useOpenModal'
import { Profile } from 'featureSliced/features/Profile'
import { Menu } from 'featureSliced/features/Profile/Menu'
import { qrMenuGET } from 'api/qrMenu/GET'
import { handleLocalStorage } from 'featureSliced/shared/hooks/handleLocalStorage'
import { getAuthUserName } from 'featureSliced/shared/utils/getAuthUserName'
import { Logo } from 'featureSliced/widgets/Logo'
import { RenderIf } from 'components/RenderIf'
import { ButtonsCC } from 'featureSliced/widgets/ButtonsMainScreen/ButtonsCC'
import { ButtonsQRM } from 'featureSliced/widgets/ButtonsMainScreen/ButtonsQRM'
import { QRMInstructions } from 'featureSliced/widgets/ButtonsMainScreen/QRMInstructions'
import { Languages } from 'featureSliced/widgets/Languages'
import { AuthModal } from 'featureSliced/features/AuthModal'
import { useMySelector } from 'hooks/useMySelector'

export type PageType = 'edit' | 'orders' | 'card' | 'payment' | null

export const MainScreen = () => {
  // Hooks
  const openModal = useOpenModal()
  const currentPage = useRef<PageType>(null)
  const { guid } = handleLocalStorage(['guid', 'registerId'])
  const isUserAuth = useMySelector((state) => state.auth.isLoggedIn)

  // State
  const [userName, setUserName] = useState<string | false>()
  const [isSidebarShowing, setIsSidebarShowing] = useState<boolean>(false)
  const [notificationsAmount, setNotificationsAmount] = useState<
    number | string
  >(0)

  // useEffect
  useEffect(() => {
    setIsSidebarShowing(false)
  }, [])

  useEffect(() => {
    getIsAuthorized()
  }, [isUserAuth])

  useEffect(() => {
    if (guid) {
      getNotificationsAmount()
    }
  }, [guid])

  // Functions
  const getIsAuthorized = async () => {
    const authUserName = await getAuthUserName()
    setUserName(authUserName)
  }

  const getNotificationsAmount = async () => {
    if (userName && process.env.REACT_APP_IS_CLIC === '1') {
      const amount = await qrMenuGET.notificationsAmount()
      if (amount > 9) {
        setNotificationsAmount('9+')
      } else {
        setNotificationsAmount(amount)
      }
    }
  }

  const openAuthModal = () => {
    openModal({
      id: 'AUTH_MODAL',
      title: '',
      components: [() => <AuthModal onLogin={getIsAuthorized} />],
    })
  }

  const handleLoyaltyButtonClick = () => {
    if (userName) openProfileModal('card')
    if (!userName) openAuthModal()
  }

  const openProfileModal = (startPage?: PageType) => {
    if (startPage) {
      currentPage.current = startPage
    }
    openModal({
      id: 'PROFILE_MODAL',
      title: 'Profile',
      startIndex: startPage ? 1 : 0,
      components: [
        ({ onNext, closeThisModal }) => (
          <Menu
            onNext={onNext}
            closeThisModal={closeThisModal}
            setCurrentPage={(value) => (currentPage.current = value)}
          />
        ),
        () => (
          <Profile
            currentPage={currentPage.current}
            setCurrentPage={(value) => (currentPage.current = value)}
          />
        ),
      ],
      onClose: () => {
        getIsAuthorized()
        getNotificationsAmount()
      },
    })
  }

  return (
    <MainScreenContainer>
      <VideoBackground />
      <Sidebar
        isSidebarShowing={isSidebarShowing}
        openProfileModal={openProfileModal}
        setIsSidebarShowing={setIsSidebarShowing}
        userName={userName}
        openAuthModal={openAuthModal}
      />
      <MainScreenControls
        setIsSidebarShowing={setIsSidebarShowing}
        notificationsAmount={notificationsAmount}
        getNotificationsAmount={getNotificationsAmount}
      />
      <Logo />
      <ButtonsContainer>
        <ButtonsQRM />
        <ButtonsCC handleLoyaltyButtonClick={handleLoyaltyButtonClick} />
      </ButtonsContainer>
      <QRMInstructions />
      <Languages />
    </MainScreenContainer>
  )
}
