export const roundValueTo = (value: number, precision = 1) => {
  return Math.round(value * (100 / precision)) / (100 / precision)
}

export const getPrecision = (currency?: string) => {
  switch (currency) {
    case 'CHF':
      return 5
    case 'EUR':
      return 1
    default:
      return 1
  }
}
