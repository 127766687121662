import { FC, useEffect, useState } from 'react'

import { RenderIf } from 'components/RenderIf'
import { qrMenuGET } from 'api/qrMenu/GET'
import { handleLocalStorage } from 'utils/handleLocalStorage'
import { NotificationsModal } from 'featureSliced/widgets/NotificationsModal'

import { useOpenModal } from 'hooks/useOpenModal'
import { useMySelector } from 'hooks/useMySelector'
import { useTranslations } from 'hooks/useTranslations'

import { ReactComponent as Burger } from 'assets/cover/burger.svg'
import { ReactComponent as Bell } from 'assets/bell.svg'

import {
  MainScreenControlsContainer,
  NotificationsButtonContainer,
  SidebarButtonContainer,
} from './styled'

type MainScreenControlsProps = {
  setIsSidebarShowing: (value: boolean) => void
  notificationsAmount: number | string
  getNotificationsAmount: () => void
}

export const MainScreenControls: FC<MainScreenControlsProps> = ({
  setIsSidebarShowing,
  notificationsAmount,
  getNotificationsAmount,
}) => {
  // Hooks
  const t = useTranslations()
  const openModal = useOpenModal()
  const { guid } = handleLocalStorage(['guid', 'registerId'])
  const selfService = useMySelector((state) => state.app.menu.selfService)
  const waiterCall = useMySelector(
    (state) => state.app.interfaceStyles.waiterCall
  )

  // useEffect
  useEffect(() => {
    if (guid) {
      getNotificationsAmount()
    }
  }, [guid])

  // Functions
  const openNotificationsModal = () => {
    openModal({
      id: 'NOTIFICATIONS_MODAL',
      title: t('common.notifications'),
      onClose: getNotificationsAmount,
      components: [
        ({ closeThisModal }) => (
          <NotificationsModal
            onDeclineClose={closeThisModal}
            onSuccessClose={closeThisModal}
          />
        ),
      ],
    })
  }

  console.log('waiterCall:', waiterCall)
  console.log('selfService:', selfService)

  return (
    <MainScreenControlsContainer>
      <SidebarButtonContainer>
        <Burger
          width="38"
          height="38"
          onClick={() => setIsSidebarShowing(true)}
        />
      </SidebarButtonContainer>

      <RenderIf
        condition={
          (!selfService && waiterCall) || process.env.REACT_APP_IS_CLIC === '1'
        }
      >
        <NotificationsButtonContainer counter={notificationsAmount}>
          <Bell width="38" height="38" onClick={openNotificationsModal} />
        </NotificationsButtonContainer>
      </RenderIf>
    </MainScreenControlsContainer>
  )
}
