import styled from 'styled-components'

type EmptyBlockProps = {
  height?: string
  customPadding?: string
}

export const CategoriesList = styled.div`
  /* height: 100vh; */
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 1vh 3vw;
  position: sticky;
  top: calc(28vw + 10px);
  background: ${({ theme }) => theme.MainBackGround};
`

export const EmptyBlock = styled('div')<EmptyBlockProps>`
  min-height: ${({ height = '130px' }) => height};
  height: ${({ height = '130px' }) => height};
  max-height: ${({ height = '130px' }) => height};
`

export const CategoryItemContainer = styled.div`
  scroll-margin-top: ${process.env.REACT_APP_IS_CLIC === '0'
    ? '140px'
    : '250px'};
`

export const CategoryItemText = styled.p`
  font-weight: 600;
  margin-bottom: 0;
  text-align: center;
  color: ${({ theme }) => theme.FontColor};
  line-height: 22px;
  margin: 40px 0 14px;
`
