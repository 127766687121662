import styled from 'styled-components'

type ToastContainerProps = {
  type: 'error' | 'warning' | 'default'
}

export const ToastsContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;

  position: fixed;
  bottom: 20px;
  right: 32px;

  z-index: 4;
  width: calc(100vw - 64px);
`

export const ToastContainer = styled('div')<ToastContainerProps>`
  /* TODO: backgroind depends on type */
  background-color: #ff5151;
  background-color: #2cb68dcc;
  background-color: ${({ theme }) => `${theme.colour}`};

  width: 100%;
  height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  border-radius: 5px;
  font-size: 14px;
  line-height: 17px;

  color: #fff;
  svg {
    margin: 0 20px;
  }
`
