import styled, { keyframes } from 'styled-components'
import { getContrastColor } from '../../../utils/getContrastColor'

type SliderContainerProps = {
  isTopScrolled: boolean
  customPadding?: string
}

type CustomOpenerProps = {
  customTop?: string
}

export const SliderContainer = styled('div')<SliderContainerProps>`
  position: fixed;
  z-index: 3;
  left: 0;
  top: ${({ isTopScrolled, customPadding = '0px' }) =>
    isTopScrolled
      ? `calc(70px + ${customPadding})`
      : `calc(0vw + ${customPadding})`};
  transition: all 0.4s ease;
  width: 100%;
  overflow: scroll;
  background-color: #434343;
`

export const Slider = styled.div`
  position: relative;
  background-color: #434343;
  height: 60px;
  overflow: scroll;
  scroll-padding: 0 10px;
  padding: 10px;
  display: flex;
  box-sizing: inherit;
  width: max-content;
  min-width: 100vw;
  /* min-height: 120px; */
`

export const Grid = styled.div`
  position: relative;
  background-color: #434343;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 10px;
  box-sizing: inherit;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 40px;
  grid-gap: 10px;
  max-height: 60vh;
`

export const CategoriesOpenerBase = styled.div`
  height: 40px;
  width: 40px;
  background-color: #434343;
  border-radius: 0 0 0 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.4s ease;

  & svg {
    transform: translate(1px, -4px);
  }
`

export const CategoriesOpener = styled(CategoriesOpenerBase)<CustomOpenerProps>`
  position: fixed;
  top: ${({ customTop }) => customTop || 0};
  right: 0;
  z-index: 2;
`

export const CategoriesOpenerFlipped = styled(CategoriesOpenerBase)`
  transform: rotate(180deg) scaleX(-1);
  background-color: #818181;
`

export const CategoriesOpenerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const AllergenSliderContainer = styled(Slider)`
  height: unset;
  padding: 0;
  margin: 10px;
  margin-top: 0;
  color: #fff;
  p {
    margin-right: 10px;
    display: flex;
    align-items: center;
    font-size: 12px;
    img {
      margin-left: 5px;
      cursor: pointer;
    }
  }
`

export const SliderItem = styled.p`
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  text-align: center;
  margin: 0;
  background-color: #818181;
  margin-right: 10px;
  padding: 0 10px;
  transition: all 0.6s ease;
  width: max-content;
  margin-right: 10px;
  color: #fff;
  overflow: visible;
`

export const GridItem = styled.div`
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.8rem;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  text-align: center;
  margin: 0;
  background-color: #818181;
  overflow: visible;
  /*  */
  width: 100%;
`

// "Are You Sure" - modal

export const Disclaimer = styled.div`
  text-align: center;
  margin: 50px 0;
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.FontColor};
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 10px;
`

export const EmojiContainer = styled('div')<any>`
  margin-right: 5px;
  width: 30px;
  height: 100%;
  position: relative;

  & > * {
    z-index: 1;
  }

  &::after {
    content: ${({ emoji }) => (emoji ? `'${emoji}'` : 'unset')};
    font-size: ${({ isSelected }) => (isSelected ? '40px' : '25px')};
    position: absolute;
    top: 0;
    left: 0;
    transform: ${({ isSelected }) =>
      isSelected ? 'translate(-15%, -15px) rotate(-25deg)' : 'unset'};
    transition: all 0.5s ease;
  }
`
