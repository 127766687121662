import moment, { Moment } from 'moment'
import { qrMenuGET } from '../api/qrMenu/GET'
import { setTimeKeepDate } from './setTimeKeppDate'
import { handleLocalStorage } from './handleLocalStorage'
import {
  IKitchenTimeSlot,
  IProduct,
} from '../store/reducers/CategoriesReducer/types'

export const getCategoriesFiltered = async (
  kitchenTimeSlots: IKitchenTimeSlot[]
) => {
  const categories = await qrMenuGET.products()
  return categories?.map((category) => ({
    ...category,
    products: category?.products?.filter((product) =>
      isProductAvailable(product, kitchenTimeSlots)
    ),
  }))
}

const isProductAvailable = (
  product: IProduct,
  kitchenTimeSlots: IKitchenTimeSlot[]
) => {
  if (process.env.REACT_APP_IS_CLIC === '0') {
    return getProductAvailabilityQRM(product, kitchenTimeSlots)
  }
  if (process.env.REACT_APP_IS_CLIC === '1') {
    return getProductAvailabilityClick(product, kitchenTimeSlots)
  }
}

const getProductAvailabilityQRM = (
  product: IProduct,
  kitchenTimeSlots: IKitchenTimeSlot[]
) => {
  if (!product.productTimeSlot) {
    return true
  } else {
    const neededSlot = getNeededSlot(product, kitchenTimeSlots)
    return moment().isBetween(
      moment(neededSlot?.timeStart, 'hh:mm'),
      moment(neededSlot?.timeEnd, 'hh:mm')
    )
  }
}

const getProductAvailabilityClick = (
  product: IProduct,
  kitchenTimeSlots: IKitchenTimeSlot[]
) => {
  if (!product.productTimeSlot) {
    return true
  } else {
    const { pickUpTime } = handleLocalStorage(['pickUpTime'])

    const PickupTime = pickUpTime ? moment(pickUpTime) : moment()

    const neededSlot = getNeededSlot(product, kitchenTimeSlots, PickupTime)
    const startMoment = setTimeKeepDate(PickupTime, neededSlot?.timeStart || '')
    const endMoment = setTimeKeepDate(PickupTime, neededSlot?.timeEnd || '')

    return PickupTime.isBetween(startMoment, endMoment)
  }
}

const getNeededSlot = (
  product: IProduct,
  kitchenTimeSlots: IKitchenTimeSlot[],
  date?: Moment
) => {
  const weekday = date ? date.isoWeekday() : moment().isoWeekday()
  return kitchenTimeSlots
    ?.find((slot) => slot.timeSlotCode === product.productTimeSlot)
    ?.schedule?.find((item) => item.day === weekday)
}
