import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DefaultToast, initialState, IToast, IToastPayload } from './types'

export const ToastsSlice = createSlice({
  name: 'toastsSlice',
  initialState,
  reducers: {
    openToast(state, action: PayloadAction<Partial<IToastPayload>>) {
      return (state = [
        ...state,
        { ...DefaultToast, ...action.payload, id: Date.now() },
      ])
    },
    closeToast(state, action: PayloadAction<number>) {
      return state?.filter((el) => el.id !== action.payload)
    },
    closeAllToasts(state) {
      return (state = [])
    },
  },
})

export default ToastsSlice.reducer
