import { Suspense, useEffect, useState } from 'react'
import { RenderIf } from '../RenderIf'
import {
  ModalContainer,
  ContentContainer,
  ComponentWrapper,
  ModalCoverOverlay,
  Title,
  ModalHeader,
  ModalTransparentHeader,
  ButtonContainer,
} from './styled'
import backArrow from '../../assets/backArrow.svg'
import cross from '../../assets/cross.svg'
import { ReactComponent as Cross } from '../../assets/cross.svg'
import { ReactComponent as BackArrow } from '../../assets/backArrow.svg'
import { toggleScroll } from '../../utils/toggleScroll'
import { useMySelector } from '../../hooks/useMySelector'
import { IModal } from '../../store/reducers/ModalsReducer/types'
import { IconStroked } from 'components/IconsContainers/styled'

export const Modal = () => {
  const { modals } = useMySelector((state) => state)
  const [positions, setPositions] = useState<number[]>([0])
  const [propsNext, setPropsNext] = useState<any>()
  const coverOverlayId = 'MODAL_COVER_OVERLAY_CLOSER'

  useEffect(() => {
    setPropsNext(undefined)
    if (modals) {
      toggleScroll(!!modals.length)
    }
    setPositions((prevPositions) => {
      return (
        modals?.map(
          ({ startIndex }, i) => prevPositions[i] || startIndex || 0
        ) || []
      )
    })
  }, [modals?.length])

  const toPrev = (index: number, props: any) => {
    setPropsNext(props)
    setPositions((prevValue) => {
      const arrayToSet = [...prevValue]
      arrayToSet[index] = arrayToSet[index] - 1
      return arrayToSet
    })
  }

  const toNext = (index: number, props: any) => {
    setPropsNext(props)
    setPositions((prevValue) => {
      const arrayToSet = [...prevValue]
      arrayToSet[index] = arrayToSet[index] + 1
      return arrayToSet
    })
  }

  const [currentClosingModalIndex, setCurrentClosingModalIndex] = useState<
    number | null
  >(null)

  const handleCloseModal = (
    modalId: string,
    modalIndex: number,
    onClose?: (modalId: string) => void
  ) => {
    if (!currentClosingModalIndex) {
      setCurrentClosingModalIndex(modalIndex)
      setTimeout(() => {
        onClose && onClose(modalId)
        setCurrentClosingModalIndex(null)
      }, 280) // NOTE: time must be 20ms less than animation time
    }
  }

  return (
    <RenderIf condition={!!modals?.length}>
      {modals?.map((currentModal: IModal, index: number) => {
        const { id, title, onClose, shouldHideCross } = currentModal
        const Component = currentModal.components[positions[index] || 0]
        return (
          <ModalCoverOverlay
            key={id}
            id={coverOverlayId}
            isClosing={currentClosingModalIndex === index}
            onClick={(e: any) => {
              if (!shouldHideCross && e.target.id === coverOverlayId) {
                handleCloseModal(id, index, onClose)
              }
            }}
          >
            <ModalContainer
              isClosing={currentClosingModalIndex === index}
              withHeader={!!title}
            >
              <RenderIf condition={!!title}>
                <ModalHeader>
                  <RenderIf condition={positions[index] > 0}>
                    <div style={{ width: '16px' }}>
                      <img
                        alt="<"
                        src={backArrow}
                        onClick={(props: any) => toPrev(index, props)}
                      />
                    </div>
                  </RenderIf>
                  <RenderIf condition={positions[index] <= 0}>
                    <div style={{ width: '16px' }} />
                  </RenderIf>
                  <Title>{title}</Title>
                  <RenderIf condition={!shouldHideCross}>
                    <img
                      alt="X"
                      width="16"
                      height="16"
                      src={cross}
                      onClick={() => handleCloseModal(id, index, onClose)}
                    />
                  </RenderIf>
                  <RenderIf condition={!!shouldHideCross}>
                    <div style={{ width: '11px' }} />
                  </RenderIf>
                </ModalHeader>
              </RenderIf>
              <RenderIf condition={!title}>
                <ModalTransparentHeader>
                  <RenderIf condition={positions[index] > 0}>
                    <ButtonContainer
                      onClick={(props: any) => toPrev?.(index, props)}
                    >
                      <IconStroked color="#fff" style={{ marginLeft: '-3px' }}>
                        <BackArrow />
                      </IconStroked>
                    </ButtonContainer>
                  </RenderIf>
                  <RenderIf condition={positions[index] <= 0}>
                    <div />
                  </RenderIf>
                  <RenderIf condition={!shouldHideCross}>
                    <ButtonContainer
                      onClick={() => handleCloseModal?.(id, index, onClose)}
                    >
                      <IconStroked color="#fff">
                        <Cross />
                      </IconStroked>
                    </ButtonContainer>
                  </RenderIf>
                </ModalTransparentHeader>
              </RenderIf>
              <ContentContainer>
                <ComponentWrapper>
                  <Suspense fallback={<div />}>
                    <Component
                      onPrev={(props: any) => toPrev(index, props)}
                      onNext={(props: any) => toNext(index, props)}
                      closeThisModal={() =>
                        handleCloseModal(id, index, onClose)
                      }
                      {...propsNext}
                    />
                  </Suspense>
                </ComponentWrapper>
              </ContentContainer>
            </ModalContainer>
          </ModalCoverOverlay>
        )
      })}
    </RenderIf>
  )
}
