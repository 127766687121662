import { ITableCloudItem } from 'store/reducers/QRMenuTableReducer/types'
import { getPrecision, roundValueTo } from 'utils/roundValueTo'

export const getProductTotalPrice = (
  product: ITableCloudItem,
  currency?: string
) => {
  const getPriceWithDiscount = (
    productParam: ITableCloudItem,
    discountP = 0,
    shouldApplyDiscount = true
  ) => {
    const discpuntValue = shouldApplyDiscount ? discountP : 0
    return roundValueTo(
      productParam.price * (1 - discpuntValue / 100),
      getPrecision(currency)
    )
  }
  const itemPrice =
    product.count * getPriceWithDiscount(product, product.discountP)
  const subItemPrice = JSON.parse(product.menuItems || '[]')?.reduce(
    (menuAcc: number, menuCurr: ITableCloudItem) => {
      return (menuAcc +=
        menuCurr.count *
        getPriceWithDiscount(
          menuCurr,
          product.discountP,
          menuCurr.isApplyDiscount
        ) *
        product.count)
    },
    0
  )
  return itemPrice + subItemPrice
}
