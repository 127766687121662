import styled from 'styled-components'

interface IconProps {
  color?: string
}

interface IconFullProps {
  fill?: string
  stroke?: string
}

export const IconStroked = styled('div')<IconProps>`
  line-height: 0;
  * {
    stroke: ${({ theme, color }) => getColor(theme, color)};
  }
`

export const IconFilled = styled('div')<IconProps>`
  line-height: 0;
  * {
    fill: ${({ theme, color }) => getColor(theme, color)};
  }
`

export const IconFull = styled('div')<IconFullProps>`
  * {
    stroke: ${({ theme, stroke }) => getColor(theme, stroke)};
    fill: ${({ theme, fill }) => getColor(theme, fill)};
  }
`

const getColor = (theme: any, color?: string) => {
  if (color) {
    return color
  } else {
    return theme.FontColor
  }
}
