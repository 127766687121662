import { RenderIf } from 'components/RenderIf'
import { useMyDispatch } from 'hooks/useMyDispatch'
import { useMySelector } from 'hooks/useMySelector'
import { FC, useEffect } from 'react'
import { ToastsSlice } from 'store/reducers/ToasterReducer'
import { IToast } from 'store/reducers/ToasterReducer/types'
import { ToastContainer, ToastsContainer } from './styled'
import { ReactComponent as ICircle } from '../../../assets/iCircle.svg'

export const Toaster = () => {
  const toasts = useMySelector((state) => state.toasts)

  return (
    <RenderIf condition={!!toasts?.length}>
      <ToastsContainer>
        {toasts?.map((toast) => (
          <ToastItem key={`TOAST_${toast.id}`} toast={toast} />
        ))}
      </ToastsContainer>
    </RenderIf>
  )
}

const ToastItem: FC<{ toast: IToast }> = ({ toast }) => {
  const dispatch = useMyDispatch()
  const closeToast = ToastsSlice.actions.closeToast

  useEffect(() => {
    const timer = setTimeout(() => dispatch(closeToast(toast.id)), toast.delay)
    return () => clearTimeout(timer)
  }, [])

  return (
    <ToastContainer type={toast.type}>
      <ICircle />
      <span>{toast.message}</span>
    </ToastContainer>
  )
}
