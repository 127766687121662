import styled from 'styled-components'

type SoldOutBannerProps = {
  isList?: boolean
}

type PriceProps = {
  isSoldOut?: boolean
}

type ListItemProps = {
  isSelected?: boolean
}

type HeartContainerProps = {
  isActive?: boolean
}

export const ProductStyled = styled('div')<ListItemProps>`
  /* height: 180px; */
  min-width: 125px;
  border-radius: 10px;
  /* margin-top: 3vw; */
  position: relative;
  background: ${({ theme }) => theme.ItemsBackGround};
  color: ${({ theme }) => theme.FontColor};
  /* border: ${({ theme, isSelected }) =>
    isSelected
      ? `0.5px solid ${theme.colour}`
      : `0.5px solid ${theme.ItemsBorders}`}; */
  border: ${({ theme }) => `0.5px solid ${theme.ItemsBorders}`};
  box-shadow: ${({ isSelected }) =>
    isSelected ? `0px 0px 7px 0px #00000026` : `unset`};
`

export const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 6px;
`

export const HeaderCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`

export const ProductCounter = styled.div`
  height: 21px;
  width: 21px;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.colour};
  color: #fff;
  font-size: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const HeartContainer = styled(ProductCounter)<HeartContainerProps>`
  background-color: ${({ isActive }) =>
    isActive ? '#ff5151' : 'rgba(111, 111, 111, 0.5)'};
`

export const ProductImageContainer = styled.div`
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  aspect-ratio: 4 / 3;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const ProductImage = styled.img`
  /* height: 57px; */
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
`

export const SubTemplateTitle = styled.p`
  /* width: 105px; */
  /* height: 86px; */

  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  color: ${({ theme }) => theme.FontColor};

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;

  margin: 0 10px 10px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
  word-break: break-all;
  hyphens: auto;
`

export const ProductTitle = styled.p`
  margin: 10px 10px;
  font-size: 13px;
  line-height: 13px;
  height: 26px;
  color: ${({ theme }) => theme.FontColor};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  /* word-break: break-word; */
  hyphens: auto;
`

export const Discount = styled.div`
  /* NOTE: must be rossed out if CHF and regular if % */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;

  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  height: 15px;

  /* text-decoration-line: line-through; */

  color: #fd2724;

  margin-right: 12px;

  flex: none;
  order: 1;
  flex-grow: 0;
`

export const Price = styled('div')<PriceProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: ${({ theme, isSoldOut }) => (isSoldOut ? '#cdcdcd40' : theme.colour)};

  margin-right: 12px;
  margin-bottom: 10px;

  flex: none;
  order: 1;
  flex-grow: 0;
  position: relative;
`

export const SoldOutBanner = styled('div')<SoldOutBannerProps>`
  position: absolute;
  top: ${({ isList }) => (isList ? '25px' : '0px')};
  right: 0px;
  white-space: nowrap;
  padding: 0px 8px;
  font-size: 14px;
  font-weight: 600;
  transform: rotate(-4deg);
  text-transform: uppercase;
  border: 2px solid #f00;
  border-radius: 5px;
  color: #f00;
`

export const AgeProtection = styled.div`
  background-color: #cdcdcd;
  color: #fff;
  border-radius: 100%;
  font-size: 11px;
  height: 21px;
  width: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AllergensContainer = styled.div`
  /* position: absolute;
  bottom: 0;
  left: 0; */
  margin: 0 10px;
  height: 18px;
  overflow-x: hidden;
  gap: 5px;
  display: flex;
  flex-direction: row;
`

export const Allergen = styled.img`
  background: #cdcdcd;
  height: 18px;
  width: 18px;
  object-fit: cover;
  border-radius: 9px;
`
