import { FC, useEffect, useState } from 'react'
import { Auth } from '@aws-amplify/auth'
import { RenderIf } from '../../../components/RenderIf'
import { useMyDispatch } from '../../../hooks/useMyDispatch'
import { useTranslations } from '../../../hooks/useTranslations'
import { PhoneNumberInput } from '../../../components/Input/PhoneNumberInput'
import { clickCollectPOST } from '../../../api/clickCollect/POST'
import { ModalsSlice } from '../../../store/reducers/ModalsReducer'
import { AuthSlice } from '../../../store/reducers/AuthReducer'
import { Spinner } from '../../../components/Spinner'
import { Button } from '../../../components/Button'
import { Input } from '../../../components/Input'
import { Error, InputContainer, LinkText, StyledText } from './styled'
import { LoyatyConfirmationType } from '.'

type LogInProps = {
  userName: string
  moveToSignUp: () => void
  moveToReset: () => void
  moveToConfirm: (userName: string) => void
  onLogin?: () => void

  isLoyaltyChecked: boolean
  loyaltyConfirmation: LoyatyConfirmationType
}

export const LogIn: FC<LogInProps> = ({
  userName,
  moveToSignUp,
  moveToReset,
  onLogin,
  isLoyaltyChecked,
  loyaltyConfirmation,
  moveToConfirm,
}) => {
  // Hooks
  const dispatch = useMyDispatch()
  const { setAuthData, signIn } = AuthSlice.actions
  const { closeModal } = ModalsSlice.actions
  const t = useTranslations()

  // State
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string[]>([''])

  // useEffects
  useEffect(() => {
    setPhone(userName)
  }, [userName])

  // Functions
  const logInFunc = async () => {
    try {
      setIsLoading(true)
      setError([])
      await Auth.signIn({ username: phone, password })

      const currentAuthUser = await Auth.currentAuthenticatedUser()
      const session = await Auth.userAttributes(currentAuthUser)

      dispatch(signIn())
      dispatch(
        setAuthData({
          firstName: session.find((el) => el.Name === 'name')?.Value || '',
          lastName:
            session.find((el) => el.Name === 'family_name')?.Value || '',
          phoneNumber:
            session.find((el) => el.Name === 'phone_number')?.Value || '',
        })
      )
      await onLogin?.()
      if (isLoyaltyChecked) {
        const { loyaltyNumber, accessCode, lastTransaction } =
          loyaltyConfirmation
        clickCollectPOST.transferCustomerCard(
          loyaltyNumber,
          accessCode,
          lastTransaction
        )
      }
      if (!isLoyaltyChecked) {
        clickCollectPOST.createCustomerCard()
      }
      setIsLoading(false)
      dispatch(closeModal('AUTH_MODAL'))
    } catch (e: any) {
      if (e.name === 'UserNotConfirmedException') {
        Auth.resendSignUp(phone)
        moveToConfirm(phone)
      } else {
        setError([e.message])
      }
      setIsLoading(false)
    }
  }

  const handlePhoneNumberChange = (value: string) => {
    if (!value.length) {
      setPhone('+')
    } else {
      setPhone(value)
    }
  }

  const handleInputBlur = (inputId: string, e: any) => {
    switch (inputId) {
      case 'phone':
        setError((prevValue) =>
          prevValue.filter((el) => el !== t('errors.shortPhoneNumber'))
        )
        if (e.target.value?.length < 9) {
          setError((prevValue) => [...prevValue, t('errors.shortPhoneNumber')])
        }
        break
      case 'password':
        setError((prevValue) =>
          prevValue.filter((el) => el !== t('errors.password8'))
        )
        if (e.target.value?.length < 8) {
          setError((prevValue) => [...prevValue, t('errors.password8')])
        }
        break
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <RenderIf condition={isLoading}>
        <Spinner type="transparent" />
      </RenderIf>
      <StyledText isHeader>{t('auth.logIn')}</StyledText>
      <InputContainer>
        <PhoneNumberInput
          value={phone}
          onChange={handlePhoneNumberChange}
          onBlur={(e: any) => handleInputBlur('phone', e)}
        />
      </InputContainer>
      <InputContainer>
        <Input
          placeholder={t('auth.password')}
          value={password}
          type="password"
          onChange={(e) => setPassword(e.currentTarget.value)}
          onBlur={(e: any) => handleInputBlur('password', e)}
        />
      </InputContainer>
      <RenderIf condition={!!error.length}>
        {error.map((err) => (
          <Error key={err}>{err}</Error>
        ))}
      </RenderIf>
      <Button
        buttonType="common"
        width="100%"
        disabled={!phone || password?.length < 8 || isLoading}
        onClick={logInFunc}
      >
        {t('auth.logIn')}
      </Button>
      <StyledText>
        Forgot your password? <LinkText onClick={moveToReset}>Reset</LinkText>
      </StyledText>
      <Button
        buttonType="stroke"
        width="100%"
        onClick={moveToSignUp}
        disabled={isLoading}
      >
        {t('auth.signUp')}
      </Button>
    </div>
  )
}
