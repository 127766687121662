export const toggleScroll = (isDisabled = false) => {
  const isiOS = /iphone|ipad|ipod/.test(
    window.navigator.userAgent.toLowerCase()
  )
  if (isiOS) {
    if (isDisabled) {
      document.body.style.overflow = 'hidden'
      document.documentElement.style.overflow = 'hidden'
    }
    if (!isDisabled) {
      document.body.style.overflow = ''
      document.documentElement.style.overflow = ''
    }
  } else {
    if (isDisabled) {
      document.body.style.overflow = 'hidden'
    }
    if (!isDisabled) {
      document.body.style.overflow = ''
    }
  }
}
