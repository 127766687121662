import { FC } from 'react'
import { GridDiv } from 'utils/gridDiv'
import { RenderIf } from 'components/RenderIf'
import { useMySelector } from 'hooks/useMySelector'
import { Button } from 'featureSliced/entities/Button'
import { useTranslations } from 'hooks/useTranslations'

import { CurrentPageType } from '../types'
import { FooterContainer, Total } from './styled'

type FooterPaymentQRMProps = {
  currentPage: CurrentPageType
  setCurrentPage: (value: CurrentPageType) => void
  getIsNextAndPayDisabled: () => boolean
  tipsValue: number
  startPaymentProccess: () => void
  isLoading: boolean
  getResultPrice: () => string
}

export const FooterPaymentQRM: FC<FooterPaymentQRMProps> = ({
  currentPage,
  setCurrentPage,
  getIsNextAndPayDisabled,
  startPaymentProccess,
  isLoading,
  getResultPrice,
}) => {
  // Hooks
  const t = useTranslations()

  // Store
  const isTipsAllowed = useMySelector((state) => state.app.menu.tips)

  // Functions
  const handleNextCartAndSplit = () => {
    if (isTipsAllowed && currentPage !== 'tips') {
      setCurrentPage('tips')
    }
    if (!isTipsAllowed) {
      startPaymentProccess()
    }
  }

  const renderPayButton = () => (
    <Button
      buttonType="common"
      onClick={handleNextCartAndSplit}
      disabled={getIsNextAndPayDisabled()}
      width="100%"
      isLoading={isLoading}
    >
      {isTipsAllowed ? t('auth.confirmButton') : t('cover.pay')}
    </Button>
  )

  return (
    <>
      <FooterContainer>
        <Total>
          <span>{t('common.total')}:</span>
          <span>{getResultPrice()}</span>
        </Total>

        {/* CART */}
        <RenderIf condition={currentPage === 'cart'}>
          <div />
          {renderPayButton()}
        </RenderIf>

        {/* TIPS */}
        <RenderIf condition={currentPage === 'tips'}>
          <GridDiv gc="1/-1">
            <Button
              buttonType="common"
              onClick={startPaymentProccess}
              disabled={getIsNextAndPayDisabled()}
              width="100%"
              isLoading={isLoading}
            >
              {t('buttons.checkout')} ({getResultPrice()})
            </Button>
          </GridDiv>
        </RenderIf>
      </FooterContainer>
    </>
  )
}
