import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState, InitialStateType } from './types'

export const AuthSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    signIn(state) {
      state.isLoggedIn = true
    },
    signOut(state) {
      console.log('TRIGGERED')
      state.isLoggedIn = false
    },
    setAuthData(state, action: PayloadAction<Partial<InitialStateType>>) {
      return (state = { ...state, ...action.payload })
    },
  },
})

export default AuthSlice.reducer
