import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IBucketProduct, initialState } from './types'

export const QRMBucketSlice = createSlice({
  name: 'bucket',
  initialState,
  reducers: {
    addProductToBucket1(state, action: PayloadAction<IBucketProduct>) {
      //
      state.products.push(action.payload)
    },
    addProductToBucket(state, action: PayloadAction<IBucketProduct>) {
      const doesExist = state.products.find((product) => {
        return product.uniqueId === action.payload.uniqueId
      })
      if (doesExist && action.payload.menuItems) {
        state.products = [
          ...state.products.map((el) => {
            console.log('el:', el)
            if (
              el.uniqueId === action.payload.uniqueId &&
              action.payload.menuItems
            ) {
              return {
                ...el,
                count: action.payload.count
                  ? action.payload.count
                  : el.count + 1,
                // menuItems: [
                //   ...(el.menuItems ? el.menuItems : []),
                //   ...action.payload.menuItems,
                // ],
              }
            }
            return el
          }),
        ]
      }
      if (doesExist && !action.payload.menuItems) {
        state.products = [
          ...state.products.filter(
            (el) => !doesExist || !(el.uniqueId === action.payload.uniqueId)
          ),
          ...(action.payload.count ? [action.payload] : []),
        ]
      }
      if (!doesExist) {
        state.products = [
          ...state.products,
          ...(action.payload.count
            ? [action.payload]
            : [{ ...action.payload, count: 1 }]),
        ]
      }
    },
    removeProductFromBucket(state, action: PayloadAction<string>) {
      state.products = state.products.filter(
        (el) => el.uniqueId !== action.payload
      )
    },
    clearBucket(state) {
      state.products = []
    },
  },
})

export default QRMBucketSlice.reducer
