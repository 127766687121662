import { qrmInstance } from '.'
import { QRMpostPathes } from '../ApiPOSTPathes'
import { getUserData } from '../../utils/getUserData'
import { handleLocalStorage } from '../../utils/handleLocalStorage'
import { IProductsToOrder } from '../../sections/Order/types'
import { ProductToPay } from '../../sections/Payment/types'
import {
  DatatransPaymentInitType,
  IPaymentComplete,
  NotificationUser,
  SaferPayPaymentInitType,
} from './types'
import { CloudOrdersPostPathes } from '../ApiGETPathes'

const withToken = true
export class qrMenuPOST {
  private static withToken = true
  public static async bookProducts(
    tableId: string,
    productsToOrder: IProductsToOrder[],
    replaceWithMWItems = false
  ) {
    const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])
    const currentUserInfo = await getUserData()
    const res: any = await qrmInstance.post(
      QRMpostPathes.ORDER_PRODUCTS,
      {
        companyId: guid,
        registerId,
        tableId,
        orderItems: productsToOrder,
        replaceWithMWItems,
        cardId:
          JSON.parse(
            currentUserInfo?.attributes?.['custom:card_collection'] || 0
          )?.[guid?.toLowerCase()] || 0,
      },
      withToken
    )
    return res
  }

  public static async paymentRequest(
    isBeforeMeal: boolean,
    tipsPercentage: number,
    tableId: string | null,
    useSavedCard: boolean,
    useCustomerCard: boolean,
    updateActiveCard: boolean,
    choosenProducts?: ProductToPay[] | any
  ): Promise<SaferPayPaymentInitType | DatatransPaymentInitType> {
    const { guid, registerId, clickCollectName, clickCollectPhone } =
      handleLocalStorage([
        'guid',
        'registerId',
        'clickCollectName',
        'clickCollectPhone',
      ])
    let params: any = {
      companyId: guid,
      tableId: tableId || 0,
      useSavedCard,
      useCustomerCard,
      updateActiveCard,
      tipsPercetage: tipsPercentage,
    }
    if (isBeforeMeal) {
      params = {
        ...params,
        registerId,
        orderItems: choosenProducts,
      }
    }
    if (!isBeforeMeal) {
      params = {
        ...params,
        choosenProducts: choosenProducts,
      }
    }
    const currentUserInfo = await getUserData()
    const sessionId = handleLocalStorage('sessionId')

    const res: SaferPayPaymentInitType = await qrmInstance.post(
      QRMpostPathes.CREATE_PAYMENT,
      {
        ...params,
        ...(clickCollectName || clickCollectPhone
          ? {
              customerInfo: {
                name: clickCollectName || '',
                phoneNumber: clickCollectPhone || '',
                sessionId,
                userId: currentUserInfo.sub,
              },
            }
          : {}),
      },
      withToken
    )
    return res
  }

  public static async paymentComplete(
    paymentVariant: 1 | 2,
    transactionId: string | null,
    orderGuid: string | null
  ) {
    const { guid } = handleLocalStorage(['guid'])
    const res: IPaymentComplete = await qrmInstance.post(
      QRMpostPathes.COMPLETE_PAYMENT,
      {
        companyId: guid,
        transactionId,
        orderGuid,
        beforeMeal: paymentVariant === 1,
      },
      withToken
    )
    return res
  }

  public static async addNotificationUser(
    token: string,
    customerName: string | null = null,
    phoneNumber: string | null = null
  ): Promise<NotificationUser | null> {
    const { guid } = handleLocalStorage(['guid'])
    let res: NotificationUser | null = null
    if (customerName && phoneNumber) {
      res = await qrmInstance.post(QRMpostPathes.ADD_NOTIFICATION_USER, {
        companyId: guid?.toLowerCase(),
        deviceToken: token,
        customerName,
        phoneNumber,
      })
    }
    return res
  }

  public static async sendReseipt(
    paymentId: string,
    email: string
  ): Promise<any> {
    const res: IPaymentComplete = await qrmInstance.post(
      QRMpostPathes.SEND_RECEIPT,
      {
        paymentId,
        email,
      },
      withToken
    )
    return res
  }

  public static async createNewPaymentVariant(): Promise<any> {
    const { guid } = handleLocalStorage(['guid'])
    const res: any = await qrmInstance.post(
      QRMpostPathes.CREATE_PAYMENT_VARIANT,
      {
        companyId: guid,
      },
      withToken
    )
    return res
  }

  public static async completeNewPaymentVariant(
    transactionId: string
  ): Promise<any> {
    const { guid } = handleLocalStorage(['guid'])
    const res: any = await qrmInstance.post(
      QRMpostPathes.CONFIRM_PAYMENT_VARIANT,
      {
        transactionId,
        companyId: guid,
      },
      withToken
    )
    return res
  }

  public static async callWaiter(tableId: number) {
    const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])

    const res: any = await qrmInstance.post(
      QRMpostPathes.CALL_WAITER,
      {
        companyId: guid,
        registerId,
        tableId,
      },
      withToken
    )
    return res
  }

  public static async updateCloudOrders(
    tableId: string | null,
    orderItems?: IProductsToOrder[],
    orderGuid?: string
  ) {
    const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])
    const res: any = await qrmInstance.post(
      CloudOrdersPostPathes.UPDATE_ORDERS,
      {
        tableId,
        orderItems,
        registerId,
        orderGuid,
        companyId: guid,
      }
    )
    return res
  }

  public static async closeCloudOrders(orderGuid: string, tableId: number) {
    const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])
    const res: any = await qrmInstance.post(CloudOrdersPostPathes.CLOSE_ORDER, {
      orderGuid,
      registerId,
      companyId: guid,
      tableId,
    })
    return res
  }
}
