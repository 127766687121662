import styled from 'styled-components'

type SecondaryImageProps = {
  isActive?: boolean
}

type KitchenItemProps = {
  isActive: boolean
}

// ProductMain

export const ProductMainContainer = styled.div`
  margin-bottom: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(85vh - 198px);
  overflow-y: scroll;
  //
  width: calc(100% + 40px);
  margin-left: -20px;
  //
  & > p {
    width: 100%;
  }
`

export const ProductMainContainerInner = styled.div`
  width: calc(100vw - 40px);
  margin-bottom: 40px;
  /* width: 100%; */
`

// Image

export const ProductMainImageContainer = styled.div`
  min-height: 260px;
  height: 260px;
  min-width: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ProductMainImage = styled.img`
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 260px;
`

export const SecondaryImage = styled('div')<SecondaryImageProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  width: 40px;
  border-radius: 50%;

  background-color: rgba(111, 111, 111, 0.5);
  color: #fff;
  background-color: ${({ isActive }) =>
    isActive ? '#ff5151' : 'rgba(111, 111, 111, 0.5)'};
  img {
    width: 18px;
    margin-left: 2px;
  }
`

export const ButtonsContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;

  display: flex;
  flex-direction: column;
  gap: 5px;
`

// Title And Price

export const TitleAndPriceContainer = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  min-height: 48px;
  margin: 20px 0 15px;
  /* margin-bottom: 10px; */
  /* padding-right: 135px;
  padding-bottom: 24px; */
  color: ${({ theme }) => theme.FontColor};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: relative;
`

export const ProductTitlePrice = styled.p`
  /* position: absolute;
  right: 0;
  bottom: 0; */
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colour};
  margin-top: 5px;
`

export const MinMaxWarning = styled(ProductTitlePrice)`
  left: 0;
  right: unset;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`

// export const ProductTotalPrice = styled.p`
//   margin: 0;
//   font-weight: 600;
//   font-size: 18px;
//   line-height: 22px;
//   color: ${({ theme }) => theme.colour};
// `

// UnitSellCodePrice

export const UnitSellCodePriceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  color: ${({ theme }) => theme.FontColor};
  & > p {
    color: ${({ theme }) => theme.FontColor};
    margin-right: 5px;
    font-size: 12px;
    width: auto;
  }
  p:nth-child(3) {
    font-weight: 600;
  }
`

export const PriceInfoContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  gap: 10px;
`

export const ProductMainPrice = styled.div`
  color: ${({ theme }) => theme.FontColor};
  background-color: ${({ theme }) => theme.FooterColor};
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  padding: 9px 22px;
`

export const ProductSellCodePrice = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colour};
`

export const CutDisclaimer = styled.p`
  font-size: 10px;
  line-height: 12px;
  margin: 15px 0;
  color: ${({ theme }) => theme.FontColor};
`

// Kitchen Info

export const KitchenInfoTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.FontColor};
  margin-bottom: 10px;
`

export const KitchenItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  width: 100%;
  margin-bottom: 20px;
  &::-webkit-scrollbar {
    height: 0px;
  }
`

export const KitchenItem = styled('div')<KitchenItemProps>`
  color: #fff;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colour : theme.ItemsBorders};
  padding: 10px 15px;
  margin-right: 10px;
  border-radius: 5px;
  text-align: center;
  line-height: 100%;
  display: flex;
  align-items: center;
`

// Allergens

export const AllergensSections = styled.div`
  display: flex;
  flex-direction: column;
`

export const AllergensTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.FontColor};
  margin-bottom: 10px;
`

export const AllergensContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  margin: 0;
  margin-bottom: 10px;
  position: relative;
`

export const AllergenItem = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-right: 25px;
    white-space: nowrap;
  }
`

export const Allergen = styled.img`
  background: #ff5151;
  height: 34px;
  width: 34px;
  object-fit: cover;
  margin-right: 7px;
  border-radius: 17px;
`

export const AllergenText = styled.p`
  color: ${({ theme }) => theme.FontColor};
`

// Description

// export const DescriptionTitle = styled.p`
//   font-weight: 600;
//   font-size: 16px;
//   line-height: 20px;
//   color: ${({ theme }) => theme.FontColor};
//   margin-bottom: 10px;
// `

export const DescriptionP = styled.p`
  /* margin-bottom: 20px; */
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.FontColor};
`

// Footer

export const Divider = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 103px;
  border-bottom: 1px solid #cdcdcd;
  height: 20px;
  background-image: linear-gradient(
    transparent,
    ${({ theme }) => theme.MainBackGround}
  );
`

export const FooterContainer = styled.div`
  margin-top: 20px;
  height: 58px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 21px;
`
