import { useEffect, useMemo, useState } from 'react'
import Auth from '@aws-amplify/auth'

import { isAuth } from 'utils/isAuth'
import { convertToPrice } from 'utils/convertToPrice'
import { getTotalPrice } from 'featureSliced/shared/utils/getTotalPrice'

import { Header } from 'components/Header'
import { RenderIf } from 'components/RenderIf'
import { errorHandler } from 'components/ErrorHandler'

import { CardsModal } from './QRMCardsModal'
import { FooterPaymentQRM } from './Footer'

import { clickCollectGET } from 'api/clickCollect/GET'
import { Spinner } from 'featureSliced/entities/Spinner'

import { Cart } from 'featureSliced/widgets/Cart'
import { Tips } from 'featureSliced/widgets/Tips'
import { SubFooter } from 'featureSliced/widgets/Cart/SubFooter'
import { AgeProtectionModal } from 'featureSliced/widgets/AgeProtectionModal'

import { useProductProtection } from 'hooks/useProductProtection'
import { useTranslations } from 'hooks/useTranslations'
import { useMySelector } from 'hooks/useMySelector'
import { useOpenModal } from 'hooks/useOpenModal'
import { usePayment } from 'hooks/usePayment'

import { ITableCloudItem } from 'store/reducers/QRMenuTableReducer/types'
import { IBucketProduct } from 'store/reducers/QRMenuBucketReducer/types'
import { CurrentPageType } from './types'

import { EmptyBlock, PaymentContainer } from './styled'

export const CheckoutQRM = () => {
  // Hooks
  const t = useTranslations()
  const openModal = useOpenModal()
  const { startQRMAfterMealPayment: startPayment } = usePayment()
  const { isProductHighlyProtected, isProductProtected } =
    useProductProtection()

  // Store
  const isUserAuth = useMySelector((state) => state.auth.isLoggedIn)
  const currency = useMySelector((state) => state.app.companyData.Currency)
  const selectedQRMProducts = useMySelector((state) => state.bucket.products)
  const paymentVariant = useMySelector((state) => state.app.menu.paymentVariant)

  const isProductsLoaded = useMySelector(
    (state) => state.products.isProductsLoaded
  )

  // State
  const [currentPage, setCurrentPage] = useState<CurrentPageType>('cart')
  const [isAVGConfirmed, setIsAVGConfirmed] = useState<boolean>(false)
  // const [isUserAuth, setIsUserAuth] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [tipsValue, setTipsValue] = useState<number>(0)
  const [isLoyaltyCardExisting, setIsLoyaltyCardExisting] =
    useState<boolean>(false)

  // useEffect
  useEffect(() => {
    checkIfLoyaltyCardExists()
  }, [])

  // useEffect(() => {
  //   checkIsAuth()
  // }, [paymentVariant])

  // Functions
  const getResultPriceWithCurrency = () => {
    const total = getTotalPrice(selectedQRMProducts)
    return `${convertToPrice(total + tipsValue)} ${currency || ''}`
  }

  const getIsNextAndPayDisabled = () => {
    return !isUserAuth && !isAVGConfirmed
  }

  const checkIfLoyaltyCardExists = async () => {
    try {
      if (isUserAuth) {
        const res = await clickCollectGET.customerCard()
        setIsLoyaltyCardExisting(!!res?.cardNumber)
      }
    } catch (e) {
      errorHandler(e)
    }
  }

  const openAgeProtectionModal = (
    itemsToPay: ITableCloudItem[] | IBucketProduct[],
    isHighlyProtected = false
  ) => {
    openModal({
      id: 'AGE_PROTECTION_MODAL',
      title: t('common.confirmYourAge'),
      components: [
        ({ closeThisModal }) => (
          <AgeProtectionModal
            isHighlyProtected={isHighlyProtected}
            startPayment={() => {
              startPaymentRequest(itemsToPay)
              closeThisModal()
            }}
          />
        ),
      ],
    })
  }

  const startPaymentProccess = () => {
    if (
      selectedQRMProducts?.some((product) =>
        isProductHighlyProtected(product.productId)
      )
    ) {
      openAgeProtectionModal(selectedQRMProducts, true)
    } else if (
      selectedQRMProducts?.some((product) =>
        isProductProtected(product.productId)
      )
    ) {
      openAgeProtectionModal(selectedQRMProducts)
    } else {
      startPaymentRequest(selectedQRMProducts)
    }
  }

  const startPaymentRequest = async (
    itemsToPay: ITableCloudItem[] | IBucketProduct[]
  ) => {
    if (isAVGConfirmed || isUserAuth) {
      try {
        setIsLoading(true)

        if (!isUserAuth) {
          await startPayment(itemsToPay, tipsValue)
        }

        if (isUserAuth) {
          const currentUserInfo = await Auth.currentUserInfo()
          const attributes = currentUserInfo?.attributes
          if (
            (!attributes || !attributes?.['custom:active_card_token']) &&
            !isLoyaltyCardExisting
          ) {
            await startPayment(itemsToPay, tipsValue)
          } else {
            setIsLoading(false)
            openModal({
              id: 'PAYMENT_CARDS_MODAL',
              title: 'Choose payment variant',
              components: [
                ({ closeThisModal }) => (
                  <CardsModal
                    isLoyaltyCardExisting={isLoyaltyCardExisting}
                    itemsToPay={itemsToPay}
                    tipsValue={tipsValue}
                    userCardData={attributes?.['custom:active_card_token']}
                    closeModal={closeThisModal}
                  />
                ),
              ],
            })
          }
        }
      } catch (e) {
        errorHandler(e)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const getProductsToShow = (isProductsLoadedParam: boolean) => {
    switch (true) {
      case !isProductsLoadedParam:
        return []
      case isProductsLoadedParam:
        return selectedQRMProducts
      default:
        return []
    }
  }

  return (
    <>
      <PaymentContainer>
        <Header />
        <EmptyBlock />
        <RenderIf condition={!isProductsLoaded}>
          <Spinner type="transparent" />
        </RenderIf>

        {/* CART */}
        <RenderIf condition={currentPage === 'cart'}>
          <Cart products={getProductsToShow(isProductsLoaded)} />
        </RenderIf>

        {/* TIPS */}
        <RenderIf condition={currentPage === 'tips'}>
          <Tips
            // total={cloudTotal}
            total={getTotalPrice(selectedQRMProducts)}
            tipsValue={tipsValue}
            onChange={setTipsValue}
          />
        </RenderIf>
        {/* SUBFOOTER */}
        <SubFooter
          isAVGConfirmed={isAVGConfirmed}
          setIsAVGConfirmed={setIsAVGConfirmed}
          //
          isUserAuth={isUserAuth}
          // setIsUserAuth={setIsUserAuth}
        />
        <EmptyBlock height="120px" />
      </PaymentContainer>

      {/* FOOTER */}
      <FooterPaymentQRM
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        //
        tipsValue={tipsValue}
        isLoading={isLoading}
        //
        startPaymentProccess={startPaymentProccess}
        getResultPrice={getResultPriceWithCurrency}
        getIsNextAndPayDisabled={getIsNextAndPayDisabled}
      />
    </>
  )
}
