export const initialState: InitialStateType = {
  isLoggedIn: false,
  firstName: '',
  lastName: '',
  phoneNumber: '',
}

export type InitialStateType = {
  isLoggedIn: boolean
  firstName: string
  lastName: string
  phoneNumber: string
}
