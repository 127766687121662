import Auth from '@aws-amplify/auth'
import { FC, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { qrMenuPOST } from '../../../../api/qrMenu/POST'
import { Button } from '../../../../components/Button'
import { errorHandler } from '../../../../components/ErrorHandler'
import { RenderIf } from '../../../../components/RenderIf'
import { useMySelector } from '../../../../hooks/useMySelector'
import { useOpenModal } from '../../../../hooks/useOpenModal'
import { useTranslations } from '../../../../hooks/useTranslations'
import { convertToPrice } from '../../../../utils/convertToPrice'
import { GridDiv } from '../../../../utils/gridDiv'
import { isAuth } from '../../../../utils/isAuth'
import { CurrentPageType, ProductToPay } from '../../types'
import { CardsModal } from './CardsModal'
import { clickCollectGET } from '../../../../api/clickCollect/GET'
import {
  isProductHighlyProtected,
  isProductProtected,
} from '../../../../utils/isProductProtected'
import { AgeProtectionModal } from './AgeProtectionModal'
import { handleLocalStorage } from '../../../../utils/handleLocalStorage'

type QRMFooterProps = {
  total: number
  currentPage: CurrentPageType
  tipsValue: number
  productsToPay: ProductToPay[]
  enableSplit: () => void
  enableTips: () => void
  enableSelfService: () => void
  renderAmountHandler: () => JSX.Element
  isSelfServicePayDisabled: boolean
  useSavedCard: boolean
  useCustomerCard: boolean
  updateActiveCard: boolean
  handlePayment: (value: () => Promise<any>) => Promise<boolean | undefined>
  isLoading: boolean
  setIsLoading: (value: boolean) => void
  isPaymentAvailable?: boolean
}

export const QRMFooter: FC<QRMFooterProps> = ({
  total,
  currentPage,
  tipsValue,
  productsToPay,
  enableSplit,
  enableTips,
  enableSelfService,
  renderAmountHandler,
  isSelfServicePayDisabled,
  useSavedCard,
  useCustomerCard,
  updateActiveCard,
  handlePayment,
  isLoading,
  setIsLoading,
  isPaymentAvailable,
}) => {
  // Hooks
  const t = useTranslations()
  const openModal = useOpenModal()

  const [searchParams] = useSearchParams()
  const tableId = searchParams.get('table')

  //Store
  const isUserAuth = useMySelector((state) => state.auth.isLoggedIn)
  const { Currency } = useMySelector((state) => state.app.companyData)
  const { paymentVariant, selfService, tips } = useMySelector(
    (state) => state.app.menu
  )

  // State
  const [isSplitEnabled, setIsSplitEnabled] = useState<boolean>(false)
  const [isPayImmediately, setIsPayImmediately] = useState<boolean>(false)
  const [isLoyaltyCardExisting, setIsLoyaltyCardExisting] =
    useState<boolean>(false)

  // useEffects
  useEffect(() => {
    checkIfLoyaltyCardExists()
  }, [])

  useEffect(() => {
    setIsSplitEnabled(paymentVariant === 2)
  }, [paymentVariant])

  useEffect(() => {
    setIsPayImmediately(selfService === 0)
  }, [selfService])

  // Functions
  const checkIfLoyaltyCardExists = async () => {
    try {
      const res = await clickCollectGET.customerCard()
      setIsLoyaltyCardExisting(!!res?.cardNumber)
    } catch (e) {
      errorHandler(e)
    }
  }

  const handlePayClick = () => {
    if (productsToPay?.some((product) => isProductHighlyProtected(product))) {
      openHighAgeProtectionModal()
    } else if (productsToPay?.some((product) => isProductProtected(product))) {
      openAgeProtectionModal()
    } else {
      startPayment()
    }
  }

  const openAgeProtectionModal = () => {
    openModal({
      id: 'AGE_PROTECTION_MODAL',
      title: t('common.confirmYourAge'),
      components: [
        ({ closeThisModal }) => (
          <AgeProtectionModal
            startPayment={() => {
              startPayment()
              closeThisModal()
            }}
          />
        ),
      ],
    })
  }

  const openHighAgeProtectionModal = () => {
    openModal({
      id: 'AGE_PROTECTION_MODAL',
      title: t('common.confirmYourAge'),
      components: [
        ({ closeThisModal }) => (
          <AgeProtectionModal
            isHighlyProtected
            startPayment={() => {
              startPayment()
              closeThisModal()
            }}
          />
        ),
      ],
    })
  }

  const startPayment = () => {
    if ((!tips || currentPage === 'tips') && isPayImmediately) {
      selectCardToPay()
    }
    if ((!tips || currentPage === 'tips') && !isPayImmediately) {
      enableSelfService()
    }
    if (tips === 1 && currentPage !== 'tips') {
      enableTips()
    }
  }

  const selectCardToPay = async () => {
    try {
      setIsLoading(true)
      if (isUserAuth) {
        const currentUserInfo = await Auth.currentUserInfo()
        const attributes = currentUserInfo?.attributes
        if (
          (!attributes || !attributes?.['custom:active_card_token']) &&
          !isLoyaltyCardExisting
        ) {
          paymentRequest(useSavedCard, useCustomerCard, updateActiveCard)
        } else {
          setIsLoading(false)
          openModal({
            id: 'PAYMENT_CARDS_MODAL',
            title: 'Choose payment variant',
            components: [
              ({ closeThisModal }) => (
                <CardsModal
                  isLoyaltyCardExisting={isLoyaltyCardExisting}
                  userCardData={attributes?.['custom:active_card_token']}
                  paymentRequest={paymentRequest}
                  closeModal={closeThisModal}
                />
              ),
            ],
          })
        }
      }
      if (!isUserAuth) {
        paymentRequest(useSavedCard, useCustomerCard, updateActiveCard)
      }
    } catch (e) {
      errorHandler(e)
    }
  }

  const paymentRequest = async (
    useSavedCardProps: boolean,
    useCustomerCardProps: boolean,
    updateActiveCardProps: boolean
  ) => {
    const res = await handlePayment(async () => {
      const {
        TransactionId,
        OrderGUID,
        PaymentType,
        PaymentProvider,
        RedirectUrl,
      }: any = await qrMenuPOST.paymentRequest(
        paymentVariant === 1,
        tipsValue,
        tableId,
        useSavedCardProps,
        useCustomerCardProps,
        updateActiveCardProps,
        productsToPay.length ? productsToPay : undefined
      )
      return {
        TransactionId,
        OrderGUID,
        PaymentType,
        PaymentProvider,
        RedirectUrl,
      }
    })
    return res
  }

  return (
    <>
      <RenderIf condition={currentPage === 'cart' || currentPage === 'split'}>
        <RenderIf condition={isSplitEnabled && currentPage === 'cart'}>
          <GridDiv gc="1 / 2">
            <Button
              buttonType="stroke"
              width="100%"
              disabled={isLoading}
              onClick={enableSplit}
            >
              {t('order.split')}
            </Button>
          </GridDiv>
        </RenderIf>
        <RenderIf condition={currentPage === 'split'}>
          <GridDiv gc="1 / 2">{renderAmountHandler()}</GridDiv>
        </RenderIf>
        <GridDiv gc={isSplitEnabled ? '2 / -1' : '1 / -1'}>
          <Button
            buttonType="common"
            width="100%"
            isLoading={isLoading}
            onClick={handlePayClick}
            disabled={!isPaymentAvailable}
          >
            {isPayImmediately ? t('cover.pay') : t('auth.confirmButton')}
          </Button>
        </GridDiv>
      </RenderIf>
      <RenderIf condition={currentPage === 'tips'}>
        <GridDiv gc="1 / -1">
          <Button
            buttonType="common"
            width="100%"
            isLoading={isLoading}
            onClick={handlePayClick}
          >
            {t('buttons.checkout')} ({convertToPrice(total)} {Currency || ''})
          </Button>
        </GridDiv>
      </RenderIf>
      <RenderIf condition={currentPage === 'selfService'}>
        <GridDiv gc="1 / -1">
          <Button
            buttonType="common"
            width="100%"
            disabled={isSelfServicePayDisabled}
            onClick={() =>
              paymentRequest(useSavedCard, useCustomerCard, updateActiveCard)
            }
            isLoading={isLoading}
          >
            {t('cover.pay')}
          </Button>
        </GridDiv>
      </RenderIf>
    </>
  )
}
