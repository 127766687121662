import { useEffect, useState } from 'react'
import { RenderIf } from 'components/RenderIf'

import { convertToPrice } from 'utils/convertToPrice'
import { Button } from 'featureSliced/entities/Button'
import { Spinner } from 'featureSliced/entities/Spinner'
import { QRMBucketSlice } from 'store/reducers/QRMenuBucketReducer'
import { getTotalPrice } from 'featureSliced/shared/utils/getTotalPrice'

import { useMySelector } from 'hooks/useMySelector'
import { useMyDispatch } from 'hooks/useMyDispatch'
import { useTranslations } from 'hooks/useTranslations'

import { TableStatuses } from 'store/reducers/QRMenuTableReducer/types'
import { BucketProducts } from './BucketProducts'

import { Header } from 'components/Header'
import { qrMenuPOST } from 'api/qrMenu/POST'
import { useSearchParams } from 'react-router-dom'
import { useOpenModal } from 'hooks/useOpenModal'
import { Error } from './Modals/errorModal'
import { Success } from 'sections/Order/OrderModal/successModal'
import { ConfirmationModal } from 'featureSliced/features/ConfirmationModal'
import { getNavLink } from 'featureSliced/shared/utils/getNavLink'
import history from 'utils/history'

import {
  BucketButtonContainer,
  BucketContainer,
  BucketFooter,
  Total,
} from './styled'
import { CustomError } from 'featureSliced/features/Error'

export const Bucket = () => {
  // Hooks
  const t = useTranslations()
  const dispatch = useMyDispatch()
  const openModal = useOpenModal()
  const [searchParams] = useSearchParams()

  // Store
  const tableStatus = useMySelector((state) => state.qrMenuTable.tableStatus)
  const orderGuid = useMySelector((state) => state.qrMenuTable.orderGuid)
  const currency = useMySelector((state) => state.app.companyData.Currency)
  const tableData = useMySelector((state) => state.common.tableData)
  const bucketProducts = useMySelector((state) => state.bucket.products)

  // State
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const tableId = searchParams.get('table')
  const clearBucket = QRMBucketSlice.actions.clearBucket
  const tableName = tableData.caption || tableData.objectTag

  // useEffect
  useEffect(() => {
    if (!bucketProducts?.length || process.env.REACT_APP_IS_CLIC !== '0') {
      history.push(getNavLink(searchParams, 'menu'))
    }
  }, [bucketProducts])

  // Modals
  const openSuccuessOrderModal = () => {
    openModal({
      id: 'SUCCESS_MODAL',
      components: [
        ({ closeThisModal }) => <Success closeModal={closeThisModal} />,
      ],
      onClose: () => {
        dispatch(clearBucket())
      },
      title: t('order.toMenu'),
    })
  }

  // Functions
  const createOrder = async (index = 0) => {
    if (!isLoading) {
      try {
        setIsLoading(true)
        const res = await qrMenuPOST.updateCloudOrders(
          tableId,
          bucketProducts,
          orderGuid
        )
        if (tableStatus === TableStatuses.FREE && res && tableId) {
          await qrMenuPOST.bookProducts(tableId, bucketProducts)
        }
        history.push(getNavLink(searchParams, ''))
        openSuccuessOrderModal()
      } catch (e: any) {
        if (e.code === 1006) {
          history.replace(getNavLink(searchParams, ''))
        } else {
          openModal({
            id: `ERROR_MODAL_${index}`,
            components: [
              ({ closeThisModal }) => (
                <ConfirmationModal
                  onConfirm={() => {
                    createOrder(index + 1)
                    closeThisModal()
                  }}
                  onCancel={() => {
                    history.push(getNavLink(searchParams, 'menu'))
                    closeThisModal()
                  }}
                  okText="Retry"
                  // TODO: translations
                  text="Failed to create order. Would you like to try again?"
                />
              ),
            ],
            title: t('errors.commonErrorMessage'),
          })
        }
      } finally {
        setIsLoading(false)
      }
    }
  }

  return (
    <>
      <RenderIf condition={isLoading}>
        <Spinner type="transparent" />
      </RenderIf>
      <Header />
      <BucketContainer>
        <BucketProducts />

        <BucketFooter>
          <Total>
            <span>Total:</span>
            <span>
              {convertToPrice(getTotalPrice(bucketProducts))} {currency || ''}
            </span>
          </Total>
          <BucketButtonContainer>
            <Button
              buttonType="common"
              width="50%"
              disabled={tableStatus === TableStatuses.BUSY || isLoading}
              onClick={createOrder}
            >
              {tableName
                ? t('order.bookTable', { tableNumber: tableName })
                : t('order.book')}
            </Button>
          </BucketButtonContainer>
        </BucketFooter>
      </BucketContainer>
    </>
  )
}
