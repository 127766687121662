import styled, { keyframes } from 'styled-components'

const opening = keyframes`
  0% { bottom: -95vh; }
  100% { bottom: 0vh; }
`

const closing = keyframes`
  100% { bottom: -95vh; }
  0% { bottom: 0%; }
`

const openingOpacity = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const closingOpacity = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`

export const ModalCoverOverlay = styled('div')<any>`
  position: fixed;
  z-index: 5;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  opacity: 1;
  animation: ${({ isClosing }) => (isClosing ? closingOpacity : openingOpacity)}
    0.3s;
`

export const ModalContainer = styled('div')<any>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  max-height: 95vh;
  min-height: 35vh;

  background: ${({ theme }) => theme.MainBackGround};
  /* border-radius: 30px 30px 0 0; */
  border-radius: ${({ withHeader }) =>
    withHeader ? '20px 20px 0 0' : '30px 30px 0 0'};
  overflow-x: hidden;
  overflow-y: scroll;

  display: grid;
  /* grid-template-rows: 60px 1fr; */
  grid-template-rows: ${({ withHeader }) => (withHeader ? '60px 1fr' : '1fr')};
  grid-template-columns: 1fr;

  animation: ${({ isClosing }) => (isClosing ? closing : opening)} 0.3s ease-out; // NOTE: time must be the same as timeout time
`

export const ModalHeader = styled.div`
  height: 60px;
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  /* background-color: red; */
  /* color: #000; */
  color: ${({ theme }) => theme.FontColor};
  padding: 0 20px;

  font-weight: 600;
  size: 16px;
`

export const ModalTransparentHeader = styled.div`
  position: absolute;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const ButtonContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: lightgrey;
  /* background-color: rgba(111, 111, 111, 0.5); */
  background-color: ${({ theme }) => theme.colour};
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Title = styled.p`
  font-weight: 600;
`

export const ContentContainer = styled.div`
  max-height: calc(85vh - 25px);
  padding: 0;
  overflow: scroll;
  position: relative;
  background-color: ${({ theme }) => theme.MainBackGround};
  &::before {
    content: '';
    width: 100%;
    height: 20px;
    position: absolute;
    background: linear-gradient(
      0deg,
      transparent,
      ${({ theme }) => theme.MainBackGround}
    );
    top: 0;
    left: 0;
  }

  &::after {
    content: '';
    width: 100%;
    height: 25px;
    position: absolute;
    background: linear-gradient(
      0deg,
      ${({ theme }) => theme.MainBackGround},
      transparent
    );
    bottom: 0;
    left: 0;
  }
`

export const ComponentWrapper = styled.div`
  margin: 0 20px;
  /* padding: 20px 0 25px; */
  padding: 0 0 25px;
  height: 100%;
  /* overflow: auto; */
`
