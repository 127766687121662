import { qrmInstance } from './index'
import { Translations } from '../../store/reducers/TranslationsReducer/types'
import { IRestaurant } from '../../store/reducers/RestaurantsReducer/types'
import { ICategory } from '../../store/reducers/CategoriesReducer/types'
import { IAllergen } from '../../store/reducers/CommonReducer/types'
import { handleLocalStorage } from '../../utils/handleLocalStorage'
import { PaymentDetailsType } from '../../sections/Payment/types'
import {
  CloudOrdersGetPathes,
  QRMgetPathes,
  WalletPathes,
} from '../ApiGETPathes'
import { CloudOrderType, IHistoryItem } from './types'
import {
  IAppResponse,
  ICompanyData,
} from '../../store/reducers/AppReducer/types'

const withToken = true
const withoutToken = false
const cacheFirst = true

export class qrMenuGET {
  private static withToken = true

  public static async companyData(payload?: {
    guid?: string
    branchId?: number
  }): Promise<ICompanyData> {
    const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])
    const registerParam = `${registerId ? `?register=${registerId}` : ''}`
    const branchParam = `${
      payload?.branchId ? `?branch=${payload.branchId}` : ''
    }`
    const res: ICompanyData = await qrmInstance.get(
      `${QRMgetPathes.GET_COMPANIES}/${payload?.guid || guid}${
        branchParam || registerParam
      }`,
      withToken,
      cacheFirst
    )
    return res
  }

  public static async companies(): Promise<any[]> {
    const res: any[] = await qrmInstance.get(
      `${QRMgetPathes.GET_COMPANIES}`,
      withoutToken,
      cacheFirst
    )
    return res
  }

  public static async setup(): Promise<IAppResponse> {
    const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])
    const company =
      process.env.REACT_APP_IS_CLIC === '0' ? 'qrmenu' : 'clickcollect'
    const registerParam = `${registerId ? `&register=${registerId}` : ''}`
    const res: IAppResponse = await qrmInstance.get(
      `${QRMgetPathes.GET_COMPANIES}/${guid}${QRMgetPathes.GET_SETUP}?app_name=${company}${registerParam}`,
      withoutToken,
      cacheFirst
    )
    return res
  }

  public static async categories(): Promise<ICategory[]> {
    const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])
    const res: ICategory[] = await qrmInstance.get(
      `${QRMgetPathes.GET_CATEGORIES}?company=${guid}&register=${registerId}`
    )
    return res
  }

  public static async products(search = ''): Promise<ICategory[]> {
    const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])
    const res: ICategory[] = await qrmInstance.get(
      `${QRMgetPathes.GET_PRODUCTS}?company=${guid}&register=${registerId}&search=${search}`
    )
    return res?.sort((a, b) => (a?.pageIndex > b?.pageIndex ? 1 : -1))
  }

  public static async allergens(): Promise<IAllergen[]> {
    const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])
    const res: IAllergen[] = await qrmInstance.get(
      `${QRMgetPathes.GET_ALLERGENS}?company=${guid}&register=${registerId}`
    )
    return res
  }

  public static async tableInfo(
    caption?: string | number,
    tableId?: string | number
  ) {
    const { guid } = handleLocalStorage(['guid'])
    const tableParam = `${tableId ? `&table=${tableId}` : ''}`
    const captionParam = `${caption ? `&caption=${caption}` : ''}`
    const res: any = await qrmInstance.get(
      `${QRMgetPathes.GET_TABLE_INFO}?company=${guid}${tableParam}${captionParam}`,
      withoutToken,
      cacheFirst
    )
    return res
  }

  public static async restaurants(
    ActiveBranchID?: string,
    guidParam?: string | null
  ): Promise<IRestaurant[]> {
    const { guid } = handleLocalStorage(['guid'])
    const branchParam = `${ActiveBranchID ? `&branch=${ActiveBranchID}` : ''}`
    const res: IRestaurant[] = await qrmInstance.get(
      `${QRMgetPathes.GET_RESTAURANTS}?company=${
        guid || guidParam
      }${branchParam}&cc_active=${process.env.REACT_APP_IS_CLIC}`,
      withoutToken,
      cacheFirst
    )
    return res
  }

  public static async paymentDetails(
    tableId: number
  ): Promise<PaymentDetailsType> {
    const { guid } = handleLocalStorage(['guid'])
    const res: PaymentDetailsType = await qrmInstance.get(
      `${QRMgetPathes.GET_PAYMENT_DETAILS}?company=${guid}&table=${tableId}`
    )
    return res
  }

  public static async ordersHistory(): Promise<IHistoryItem[]> {
    const { guid } = handleLocalStorage(['guid'])
    const company =
      process.env.REACT_APP_IS_CLIC === '0' ? 'qrmenu' : 'clickcollect'
    const res: IHistoryItem[] = await qrmInstance.get(
      `${QRMgetPathes.GET_ORDERS}?company=${guid}&app_name=${company}`,
      withToken
    )
    return res
  }

  public static async ordersHistoryWallet(
    walletCardId: string
  ): Promise<IHistoryItem[]> {
    const guid = handleLocalStorage('guid')
    const res: IHistoryItem[] = await qrmInstance.get(
      `${WalletPathes.GET_ORDERS}?company=${guid}&cardId=${walletCardId}`
    )
    return res
  }

  public static async getSpecials(): Promise<any[]> {
    const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])
    const registerParam = `${registerId ? `&register=${registerId}` : ''}`
    const res: any[] = await qrmInstance.get(
      `${QRMgetPathes.GET_SPECIALS}?company=${guid}${registerParam}`
    )
    return res
  }

  public static async getKitchenTimeSlots() {
    const { guid } = handleLocalStorage(['guid'])
    const res: any = await qrmInstance.get(
      `${QRMgetPathes.GET_KITCHEN_TIME_SLOTS}?company=${guid}`
    )
    return res
  }

  public static async translations(
    appName = 'clickCollect'
  ): Promise<Translations> {
    const res = await qrmInstance.get(
      `${QRMgetPathes.GET_TRANSLATIONS}?app=${appName}`,
      withoutToken,
      cacheFirst
    )
    return res
  }

  public static async notifications() {
    const { guid } = handleLocalStorage(['guid'])
    const appName =
      process.env.REACT_APP_IS_CLIC === '0' ? 'qrmenu' : 'clickcollect'
    // TODO: add type
    const result: any = await qrmInstance.get(
      `${QRMgetPathes.NOTIFICATIONS}?app_name=${appName}&company=${guid}`,
      withToken
    )
    return result
  }

  public static async notificationsAmount() {
    const { guid } = handleLocalStorage(['guid'])
    const appName =
      process.env.REACT_APP_IS_CLIC === '0' ? 'qrmenu' : 'clickcollect'
    // TODO: add type
    const result: any = await qrmInstance.get(
      `${QRMgetPathes.NOTIFICATIONS_AMOUNT}?app_name=${appName}&company=${guid}`,
      withToken
    )
    return result?.unreadCount || 0
  }

  public static async cloudOrders(tableId?: string | number | null) {
    const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])
    const registerParam = `${registerId ? `&registerId=${registerId}` : ''}`
    const tableParam = `${tableId ? `&tableId=${tableId}` : ''}`

    const res: CloudOrderType = await qrmInstance.get(
      `${CloudOrdersGetPathes.GET_ORDERS}?companyId=${guid}${registerParam}${tableParam}`,
      withoutToken
    )
    return res
  }

  public static async modVer(modVer: number, orderGuid?: string) {
    const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])
    const registerParam = `${registerId ? `&registerId=${registerId}` : ''}`
    const orderGuidParam = `${orderGuid ? `&orderGuid=${orderGuid}` : ''}`
    const modVerParam = `${modVer ? `&modVer=${modVer}` : ''}`

    const res: CloudOrderType = await qrmInstance.get(
      `${CloudOrdersGetPathes.GET_MOD_VER}?companyId=${guid}${registerParam}${orderGuidParam}${modVerParam}`,
      withoutToken
    )
    return res
  }
}
