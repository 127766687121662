import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState, ITableCloudItem, TableStatuses } from './types'
import { handleLocalStorage } from 'featureSliced/shared/hooks/handleLocalStorage'

export const QRMTableSlice = createSlice({
  name: 'tableSlice',
  initialState,
  reducers: {
    setTableId(state, action: PayloadAction<number | string>) {
      state.tableId = action.payload
    },
    setIsTableLoading(state, action: PayloadAction<boolean>) {
      state.isTableLoading = action.payload
    },
    setTableStatus(state, action: PayloadAction<TableStatuses>) {
      state.tableStatus = action.payload
    },
    setIsTableDataLoaded(state) {
      state.isTableDataLoaded = true
      state.isTableLoading = false
    },
    setCloudItems(state, action: PayloadAction<ITableCloudItem[]>) {
      state.cloudItems = action.payload
    },
    setModVer(state, action: PayloadAction<number>) {
      state.modVer = action.payload
    },
    setOrderGuid(state, action: PayloadAction<string | undefined>) {
      state.orderGuid = action.payload
    },
    setCloudTotal(state, action: PayloadAction<number>) {
      state.cloudTotal = action.payload
    },
  },
})

export default QRMTableSlice.reducer
