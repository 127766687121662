export const initialState: IFilters = {
  allergens: [],
  diet: [],
}

export interface IFilters {
  allergens: number[]
  diet: string[]
  search?: string
}
