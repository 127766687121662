import { FC, useEffect, useState } from 'react'
import { RenderIf } from '../../../components/RenderIf'
import { Confirm } from './Confirm'
import { LogIn } from './LogIn'
import { ResetPassword } from './Reset'
import { SignUp } from './SignUp'

type PageType = 'login' | 'signup' | 'confirm' | 'reset'

type AuthModalProps = {
  onLogin?: () => void
  initialPage?: PageType
}

export type LoyatyConfirmationType = {
  loyaltyNumber: string
  accessCode: string
  lastTransaction: string
}
export type LoyatyConfirmationSETType = keyof LoyatyConfirmationType

export const AuthModal: FC<AuthModalProps> = ({
  onLogin,
  initialPage = 'login',
}) => {
  //
  // State
  const [authType, setAuthType] = useState<PageType>('login')
  const [userName, setUserName] = useState<string>('')
  const [isLoyaltyChecked, setIsLoyaltyChecked] = useState(false)
  const [loyaltyConfirmation, setLoyaltyConfirmation] =
    useState<LoyatyConfirmationType>({
      loyaltyNumber: '',
      accessCode: '',
      lastTransaction: '',
    })

  // useEffects
  useEffect(() => {
    if (initialPage) {
      setAuthType(initialPage)
    }
  }, [])

  // Functions
  const handleLoyaltyConfirmation = (
    type: LoyatyConfirmationSETType,
    value: string
  ) => {
    setLoyaltyConfirmation((prevValue) => ({ ...prevValue, [type]: value }))
  }

  const moveToConfirm = (userName: string) => {
    setUserName(userName)
    setAuthType('confirm')
  }

  return (
    <div>
      <RenderIf condition={authType === 'login'}>
        <LogIn
          userName={userName}
          moveToSignUp={() => setAuthType('signup')}
          moveToReset={() => setAuthType('reset')}
          onLogin={onLogin}
          //
          isLoyaltyChecked={isLoyaltyChecked}
          loyaltyConfirmation={loyaltyConfirmation}
          moveToConfirm={moveToConfirm}
        />
      </RenderIf>
      <RenderIf condition={authType === 'reset'}>
        <ResetPassword moveToLogIn={() => setAuthType('login')} />
      </RenderIf>
      <RenderIf condition={authType === 'signup'}>
        <SignUp
          isLoyaltyChecked={isLoyaltyChecked}
          setIsLoyaltyChecked={setIsLoyaltyChecked}
          //
          loyaltyConfirmation={loyaltyConfirmation}
          handleLoyaltyConfirmation={handleLoyaltyConfirmation}
          //
          moveToLogIn={() => setAuthType('login')}
          moveToConfirm={moveToConfirm}
        />
      </RenderIf>
      <RenderIf condition={authType === 'confirm'}>
        <Confirm userName={userName} moveToLogIn={() => setAuthType('login')} />
      </RenderIf>
    </div>
  )
}
