import { useOpenModal } from './useOpenModal'
import { ConfirmationModal } from 'featureSliced/features/ConfirmationModal'
import { useMyDispatch } from './useMyDispatch'
import { loadTableData } from 'store/reducers/QRMenuTableReducer/actionCreator'
import { useSearchParams } from 'react-router-dom'
import { TableStatuses } from 'store/reducers/QRMenuTableReducer/types'
import history from 'utils/history'
import { getNavLink } from 'featureSliced/shared/utils/getNavLink'
import { useMySelector } from './useMySelector'
import TableSelect from 'featureSliced/features/TableSelect'
import { ModalsSlice } from 'store/reducers/ModalsReducer'
import { ToastsSlice } from 'store/reducers/ToasterReducer'

export const useTableStatus = () => {
  const dispatch = useMyDispatch()
  const openModal = useOpenModal()
  const [searchParams] = useSearchParams()
  const tableId = searchParams.get('table')
  const paymentVariant = useMySelector((state) => state.app.menu.paymentVariant)
  const openToast = ToastsSlice.actions.openToast

  const openBusyModal = () => {
    // TODO: translations
    dispatch(
      openToast({
        message: 'Selected table currently is locked by another device!',
      })
    )
  }

  const openEmptyBasketModal = () => {
    dispatch(
      openToast({
        message: 'Your basket is empty at the moment.',
      })
    )
  }

  const openTableIdPrompt = (
    route: '/menu' | '/payment',
    lastStepCallback: () => void
  ) => {
    openModal({
      id: 'TABLE_SELECTION_MODAL',
      title: '',
      shouldHideCross: true,
      components: [
        ({ closeThisModal }) => (
          <TableSelect
            onClose={(tableId?: string | number) => {
              checkTableAndMoveToPaymentInner(route, lastStepCallback, tableId)
              closeThisModal()
            }}
          />
        ),
      ],
    })
  }

  const handleActionsAfterCheck = (
    tableStatus: TableStatuses,
    tableIdParam?: string,
    redirectTo?: '/menu' | '/payment' | false
  ) => {
    const currentPath = location.pathname

    if (currentPath === '/' && redirectTo === '/menu') {
      history.push(`menu?table=${tableIdParam}`)
      if (tableStatus === TableStatuses.BUSY) {
        openBusyModal()
      }
      return tableStatus
    }

    if (currentPath === '/' && redirectTo === '/payment') {
      if (tableStatus === TableStatuses.IN_USE) {
        history.push(`payment?table=${tableIdParam}`)
      }

      if (tableStatus === TableStatuses.FREE) {
        openEmptyBasketModal()
      }

      if (tableStatus === TableStatuses.BUSY) {
        openBusyModal()
      }
      return tableStatus
    }

    if (currentPath === '/menu') {
      if (tableStatus === TableStatuses.BUSY) {
        openBusyModal()
        return tableStatus
      }
    }

    if (currentPath === '/payment') {
      if (paymentVariant === 1 && tableStatus !== TableStatuses.FREE) {
        // tableStatus must be FREE for "Pay before meal"
        history.replace(getNavLink(searchParams, ''))
      }

      if (paymentVariant === 2 && tableStatus === TableStatuses.BUSY) {
        history.replace(getNavLink(searchParams, ''))
        openBusyModal()
      }

      if (paymentVariant === 2 && tableStatus === TableStatuses.FREE) {
        history.replace(getNavLink(searchParams, ''))
        openEmptyBasketModal()
      }

      return tableStatus
    }

    return tableStatus
  }

  const checkTableAndMoveToPaymentInner = (
    route: '/menu' | '/payment',
    lastStepCallback: () => void,
    tableIdParam?: string | number
  ) => {
    if (tableId || tableIdParam) {
      return dispatch(loadTableData(tableId || `${tableIdParam}`))
        .then((tableStatus) => {
          return handleActionsAfterCheck(
            tableStatus,
            tableId || `${tableIdParam}`,
            route
          )
        })
        .then((tableStatus) => {
          lastStepCallback()
          return tableStatus
        })
    }
  }

  const checkTableAndMoveTo = (
    route: '/menu' | '/payment',
    lastStepCallback: () => void
  ) => {
    if (tableId) {
      return checkTableAndMoveToPaymentInner(route, lastStepCallback)
    } else {
      openTableIdPrompt(route, lastStepCallback)
    }
  }

  const checkTableStatus = (tableIdParam?: string | number) => {
    console.log('checkTableStatus location', location.pathname)
    if (
      (tableId || tableIdParam) &&
      (location.pathname === '/menu' || location.pathname === '/payment')
    ) {
      return dispatch(loadTableData(tableId || tableIdParam || null)).then(
        (tableStatus) => {
          return handleActionsAfterCheck(
            tableStatus,
            tableId || tableIdParam?.toString()
          )
        }
      )
    }
  }

  return { checkTableStatus, checkTableAndMoveTo }
}
