import { FC, useState } from 'react'
import { useMySelector } from '../../../../hooks/useMySelector'
import { convertToPrice } from '../../../../utils/convertToPrice'
import { DataTrans } from '../../../../components/Checkout/DataTrans'
import { useTranslations } from '../../../../hooks/useTranslations'
import { errorHandler } from '../../../../components/ErrorHandler'
import { RenderIf } from '../../../../components/RenderIf'
import history from '../../../../utils/history'

import { CurrentPageType, ProductToPay } from '../../types'
import { PreviewFooter, Total } from '../../styled'

import { CCFooter } from './ClickCollectFooter'
import { QRMFooter } from './QrMenuFooter'
import { openLink } from '../../../../utils/openLink'
import { useOpenModal } from '../../../../hooks/useOpenModal'
import { AskWaiterModal } from '../../../MainScreen/AskWaiterModal'

type FooterProduct = {
  total: number
  currentPage: CurrentPageType
  tipsValue: number
  productsToPay: ProductToPay[]
  enableSplit: () => void
  enableTips: () => void
  enableSelfService: () => void
  renderAmountHandler: () => JSX.Element
  isSelfServicePayDisabled: boolean
  isPaymentAvailable?: boolean
  useSavedCard: boolean
  useCustomerCard: boolean
  updateActiveCard: boolean
}

export const Footer: FC<FooterProduct> = ({
  enableSplit,
  renderAmountHandler,
  ...footerProps
}) => {
  // Hooks
  const t = useTranslations()
  const openModal = useOpenModal()

  //Store
  const { Currency } = useMySelector((state) => state.app.companyData)

  // State
  const [transactionId, setTransactionId] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // Functions
  const openAskWaiterModal = () => {
    openModal({
      id: 'ASK_WAITER',
      components: [
        // TODO: check all AskWaiterModal modals. the only thing they do is "onClose"
        ({ closeThisModal }) => <AskWaiterModal onClose={closeThisModal} />,
      ],
    })
  }

  const handlePayment = async (requestPayment: any) => {
    try {
      setIsLoading(true)
      const {
        TransactionId,
        orderNr,
        OrderGUID,
        PaymentType,
        PaymentProvider,
        RedirectUrl,
      } = await requestPayment()
      if (
        PaymentProvider === 1 &&
        TransactionId &&
        !OrderGUID &&
        PaymentType !== 'customerCard'
      ) {
        setTransactionId(TransactionId)
      }
      if (PaymentProvider === 2 && RedirectUrl) {
        openLink(RedirectUrl)
      }
      if (TransactionId && (OrderGUID || PaymentType === 'customerCard')) {
        history.push(`/?datatransTrxId=${TransactionId}`)
      }
      if (!TransactionId && orderNr) {
        history.replace(`/?payment_success_nr=${orderNr}`)
      }
      return true
    } catch (error) {
      openAskWaiterModal()
    } finally {
      setIsLoading(false)
    }
  }

  const handlePaymentСС = async (requestPayment: any) => {
    try {
      setIsLoading(true)
      await requestPayment()
      return true
    } catch (error) {
      console.log('error')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <PreviewFooter>
      <RenderIf condition={!!transactionId}>
        <DataTrans transactionId={transactionId} />
      </RenderIf>
      <Total>
        <span>{t('common.total')}:</span>
        <span>
          {convertToPrice(footerProps.total)} {Currency || ''}
        </span>
      </Total>
      <RenderIf condition={process.env.REACT_APP_IS_CLIC === '0'}>
        <QRMFooter
          {...footerProps}
          enableSplit={enableSplit}
          renderAmountHandler={renderAmountHandler}
          handlePayment={handlePayment}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </RenderIf>
      <RenderIf condition={process.env.REACT_APP_IS_CLIC === '1'}>
        <CCFooter
          {...footerProps}
          handlePayment={handlePaymentСС}
          isLoading={isLoading}
        />
      </RenderIf>
    </PreviewFooter>
  )
}
