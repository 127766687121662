import { FC, lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { SpinnerOverlay } from './components/OverlaySpinner'

// import { MainScreen } from './sections/MainScreen'
import { Menu } from './sections/Menu' // Menu is the most used section. Delaying it affects ux
// import { Order } from './sections/Order'
// import { Payment } from './sections/Payment'
import { Wishlist } from './sections/Wishlist'
import { Restaurants } from './sections/Restaurants'
import { NetworkError } from './sections/NetworkError' // NetworkError must be loaded in case there is not connection
import { useMySelector } from './hooks/useMySelector'
import { handleLocalStorage } from './utils/handleLocalStorage'

import { Bucket } from 'featureSliced/padges/Bucket'
import { MainScreenHandler as MainScreen } from 'featureSliced/padges/MainScreen/mainScreenHandler'
import { Payment } from 'featureSliced/padges/Payment'
import { CheckoutQRM } from 'featureSliced/padges/CheckoutQRM'

const Wallet = lazy((): any => import('./sections/Wallet'))
const Welcome = lazy((): any => import('./sections/Welcome'))
const TableReservation = lazy((): any => import('./sections/TableReservation'))

type AppRouterProps = {
  initialLoading?: () => void
  initLoadMenuData?: () => Promise<void>
}

export const AppRouter: FC<AppRouterProps> = ({
  initialLoading,
  initLoadMenuData,
}) => {
  const paymentVariant = useMySelector((state) => state.app.menu.paymentVariant)
  const { bookingVariant } = useMySelector((state) => state.app.menu)
  const registerId = handleLocalStorage('registerId')

  const handleInitialLoading = async () => {
    initialLoading && (await initialLoading())
  }

  const handleInitLoadMenuData = async () => {
    initLoadMenuData && (await initLoadMenuData())
  }

  return (
    <Suspense fallback={<SpinnerOverlay isLoading />}>
      <Routes>
        <Route path="/" element={<MainScreen />} />
        <Route path="/restaurants" element={<Restaurants />} />
        <Route path="/network-error" element={<NetworkError />} />

        <Route
          path="/wishlist"
          element={
            <PrivateRoute isAllowed={registerId}>
              <Wishlist />
            </PrivateRoute>
          }
        />
        <Route
          path="/welcome"
          element={<Welcome loadApp={handleInitialLoading} />}
        />
        <Route
          path="/reservation"
          element={
            <PrivateRoute isAllowed={process.env.REACT_APP_IS_CLIC === '0'}>
              <TableReservation loadApp={handleInitialLoading} />
            </PrivateRoute>
          }
        />
        <Route
          path="/booking"
          element={
            <PrivateRoute isAllowed={process.env.REACT_APP_IS_CLIC === '0'}>
              <TableReservation loadApp={handleInitialLoading} />
            </PrivateRoute>
          }
        />
        <Route
          path="/order"
          element={
            <PrivateRoute isAllowed={process.env.REACT_APP_IS_CLIC === '0'}>
              <Bucket />
            </PrivateRoute>
          }
        />

        <Route
          path="/checkout"
          element={
            <PrivateRoute
              isAllowed={
                // unavailable for pay AFTER meal
                paymentVariant !== 2 && process.env.REACT_APP_IS_CLIC === '0'
              }
            >
              <CheckoutQRM />
            </PrivateRoute>
          }
        />

        <Route
          path="/payment"
          element={
            <PrivateRoute
              isAllowed={
                // unavailable for pay BEFORE meal
                paymentVariant !== 1 || process.env.REACT_APP_IS_CLIC === '1'
              }
            >
              <Payment />
            </PrivateRoute>
          }
        />

        <Route
          path="/menu"
          element={
            <PrivateRoute
              isAllowed={
                bookingVariant !== 3 || process.env.REACT_APP_IS_CLIC === '1'
              }
            >
              <Menu loadMenuData={handleInitLoadMenuData} />
            </PrivateRoute>
          }
        />

        <Route
          path="/wallet"
          element={
            <PrivateRoute isAllowed={process.env.REACT_APP_IS_CLIC === '1'}>
              <Wallet />
            </PrivateRoute>
          }
        >
          <Route path="" />
          <Route path="card" />
          <Route path="orders" />
          {/* <Route path="refill" /> */}
          <Route path="scanner" />
        </Route>

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  )
}

const PrivateRoute: FC<any> = ({ isAllowed = true, children }) => {
  if (isAllowed) {
    return children
  }

  return <Navigate to="/" />
}
