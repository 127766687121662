import styled from 'styled-components'

type AmountHandlerContainerProps = {
  isSmall?: boolean
}

export const AmountHandlerContainer = styled.div<AmountHandlerContainerProps>`
  width: 100%;
  /* height: ${({ isSmall }) => (isSmall ? '50px' : '58px')}; */
  height: 42px;
  color: ${({ theme }) => theme.FontColor};
  padding: 0 12px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
`

export const AmountHandlerButton = styled('span')<any>`
  width: 42px;
  height: 42px;
  border-radius: 100%;
  /* background-color: ${({ theme }) => theme.colour}; */
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme, disabled }) =>
    disabled ? 'transparent' : theme.colour};
  border: ${({ disabled }) => (disabled ? '1px solid #cdcdcd' : 'unset')};
`

export const AmountHandlerCounter = styled.div`
  font-size: 20px;
  font-weight: 700;
  height: 100%;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
`
