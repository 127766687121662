import { FC, MouseEvent } from 'react'
import { IProduct } from '../../store/reducers/CategoriesReducer/types'
import { ProductListed } from './ProductListed/ProductListed'
import { ProductsList } from './styled'

type ProductsProps = {
  products: IProduct[]
  onProductClick: (product: IProduct, e: MouseEvent<HTMLElement>) => void
  getAmount?: (product: IProduct) => number
}

export const Products: FC<ProductsProps> = ({
  products,
  onProductClick,
  getAmount,
}) => {
  const findAmount = (product: IProduct) => getAmount?.(product)
  const getIsSelected = (product: IProduct) => !!getAmount?.(product)

  return (
    <ProductsList>
      {products?.map((product, i) => {
        // Variables
        // const includedItems = [
        //   ...(product?.menuItems ? product.menuItems : []),
        // ].reduce((acc: any[], curr: any) => {
        //   const foundObject = acc.find(
        //     (el: any) => el.productId === curr.productId
        //   )
        //   if (foundObject) {
        //     return [
        //       ...acc.filter((el: any) => el.productId !== curr.productId),
        //       {
        //         ...curr,
        //         price: foundObject.price + curr.price,
        //         amount: foundObject.amount + 1,
        //       },
        //     ]
        //   } else {
        //     return [...acc, { ...curr, amount: 1 }]
        //   }
        // }, [])

        // let selectedKitchenInfo: string[] = []
        // if (typeof product?.kitchenInfo === 'string') {
        //   selectedKitchenInfo = Object.values(JSON.parse(product?.kitchenInfo))
        // }

        return (
          <ProductListed
            isSelected={getIsSelected(product)}
            amount={findAmount(product)}
            key={`${product?.id}_${i}`}
            product={product}
            onProductClick={onProductClick}
            // includedItems={includedItems}
            // selectedKitchenInfo={selectedKitchenInfo}
          />
        )
      })}
    </ProductsList>
  )
}
