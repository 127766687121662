import { FC, MouseEvent, useState } from 'react'

import { convertToPrice } from '../../../utils/convertToPrice'
import { getPrice } from '../../../utils/getProductPrice'
import { AllergenImages } from '../../../utils/AllergenImages'
import { isProductProtected } from '../../../utils/isProductProtected'
import { getProtectedAge } from '../../../utils/getProtectedAge'

import { ReactComponent as Heart } from '../../../assets/hearts/full_xs.svg'
import { ReactComponent as ClocheIcon } from '../../../assets/cloches/clocheSquare.svg'
import { ReactComponent as Flatware } from '../../../assets/flatware.svg'

import { IProduct } from '../../../store/reducers/CategoriesReducer/types'
import { IOrderProduct } from '../../../sections/Order/types'
import { useWishlist } from '../../../hooks/useWishlist'
import { useMySelector } from '../../../hooks/useMySelector'
import { useTranslations } from '../../../hooks/useTranslations'
import { RenderIf } from '../../RenderIf'

import { IconFilled } from '../../IconsContainers/styled'
import {
  AgeProtection,
  Allergen,
  AllergensContainer,
  Discount,
  Header,
  HeaderCol,
  HeartContainer,
  Price,
  ProductCounter,
  ProductImage,
  ProductImageContainer,
  ProductStyled,
  ProductTitle,
  SoldOutBanner,
  SubTemplateTitle,
} from './styled'

type ProductProps = {
  product: IProduct | IOrderProduct
  amount: number
  onProductClick?: (product: IProduct, e: MouseEvent<HTMLElement>) => void
}

export const ProductGrided: FC<ProductProps> = ({
  product,
  amount,
  onProductClick,
}) => {
  // Hooks
  const t = useTranslations()
  const handleWishlist = useWishlist()

  // Store
  const { wishlist } = useMySelector((state) => state)
  const { Currency } = useMySelector((state) => state.app.companyData)
  const { colour } = useMySelector((state) => state.app.interfaceStyles)

  // State
  const [isImgErrored, setIsImageErrored] = useState(false)

  // Functions
  const isInWishlist = (idToCheck: number) => {
    return !!wishlist?.find((listItem) => listItem === idToCheck)
  }

  const handleWishListClick = (e: any) => {
    e.stopPropagation()
    handleWishlist(product?.id)
  }

  const handleProductClick = (e: MouseEvent<HTMLElement>) => {
    if (onProductClick && Boolean(!product?.soldOut)) {
      onProductClick(product, e)
    }
  }

  return (
    <ProductStyled onClick={handleProductClick} isSelected={!!amount}>
      <RenderIf condition={Boolean(product.subTemplateItems?.length)}>
        <ProductImageContainer>
          <IconFilled color={colour}>
            <Flatware />
          </IconFilled>
        </ProductImageContainer>
        <SubTemplateTitle>{product?.title}</SubTemplateTitle>
      </RenderIf>
      <RenderIf condition={Boolean(!product.subTemplateItems?.length)}>
        <ProductImageContainer>
          <Header>
            <HeaderCol>
              <HeartContainer isActive={isInWishlist(product?.id)}>
                <IconFilled color="#fff" onClick={handleWishListClick}>
                  <Heart />
                </IconFilled>
              </HeartContainer>
              <RenderIf condition={isProductProtected(product)}>
                <AgeProtection>{getProtectedAge(product)}</AgeProtection>
              </RenderIf>
            </HeaderCol>
            <RenderIf condition={!!amount}>
              <ProductCounter>{amount}</ProductCounter>
            </RenderIf>
          </Header>
          <RenderIf condition={!isImgErrored}>
            <ProductImage
              src={product?.imageUrl || 'ERRORED_IMAGE'}
              onError={({ currentTarget }) => {
                setIsImageErrored(true)
                currentTarget.onerror = null // prevents looping
              }}
              alt="product"
              height="57"
              width="125"
            />
          </RenderIf>
          <RenderIf condition={isImgErrored}>
            <IconFilled color={`${colour}80`}>
              <ClocheIcon />
            </IconFilled>
          </RenderIf>
        </ProductImageContainer>
        <ProductTitle>{product?.title}</ProductTitle>
        <AllergensContainer>
          {product?.allergens?.split(', ').map((el) => (
            <Allergen
              key={el}
              alt={el}
              src={AllergenImages[el] || AllergenImages[0]}
            />
          ))}
        </AllergensContainer>
        <Discount>{product.discountPrice || ''}</Discount>
        <RenderIf
          condition={
            Boolean(getPrice(product)) &&
            Boolean(!product.subTemplateItems?.length)
          }
        >
          <Price isSoldOut={product?.soldOut}>
            {convertToPrice(getPrice(product))} {Currency || ''}
            <RenderIf condition={Boolean(product?.soldOut)}>
              <SoldOutBanner>{t('products.soldOut')}</SoldOutBanner>
            </RenderIf>
          </Price>
        </RenderIf>
      </RenderIf>
    </ProductStyled>
  )
}
