export enum QRMgetPathes {
  GET_COMPANIES = '/companies',
  GET_SETUP = '/setup',
  GET_CATEGORIES = '/categories',
  GET_PRODUCTS = '/products',
  GET_ALLERGENS = '/allergens',
  GET_TABLE_INFO = '/table',
  GET_RESTAURANTS = '/restaurants',
  GET_PAYMENT_DETAILS = '/payment',
  GET_ORDERS = '/orders',
  GET_SPECIALS = '/price-and-discounts',
  GET_KITCHEN_TIME_SLOTS = '/kitchen-time-slots',
  GET_TRANSLATIONS = '/translations',
  NOTIFICATIONS = '/notification/list',
  NOTIFICATIONS_AMOUNT = '/notification/unread',
}

export enum CCgetPathes {
  CARD = '/card',
  CARD_DETAILS = '/card/load',
}

export enum WalletPathes {
  GET_CARD = '/wallet/card',
  GET_ORDERS = '/wallet/orders',
  // WALLET_CARD_REFILL = '/wallet/cardLoadDetails', // TODO: delete from BE also
  START_CARD_REFILL = '/wallet/card/load/create',
  COMPLETE_CARD_REFILL = '/wallet/card/load/complete',
}

export enum TRgetPathes {
  GET_RESERVATION = '',
  GET_RESERVATION_ROOMS = '/rooms',
  GET_AVAILABILITY = '/availability',
}

export enum MWgetPathes {
  GET_TABLE_STATUS = '/table/status',
  GET_EXCHANGE_STATUS = '/table/items',
}

export enum CloudOrdersGetPathes {
  GET_ORDERS = '/cloudorders/item',
  GET_MOD_VER = '/cloudorders/mod-ver',
}

export enum CloudOrdersPostPathes {
  UPDATE_ORDERS = '/cloudorders/update',
  CLOSE_ORDER = '/cloudorders/close',
}
