import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState } from './types'

export const FiltersSlice = createSlice({
  name: 'filtersSlice',
  initialState,
  reducers: {
    addAllergen(state, action: PayloadAction<number>) {
      state.allergens.push(action.payload)
    },
    deleteAllergen(state, action: PayloadAction<number>) {
      state.allergens = [
        ...state.allergens.filter((el) => el !== action.payload),
      ]
    },
    discardAllergens(state) {
      state.allergens = []
    },
    addDiet(state, action: PayloadAction<string>) {
      state.diet.push(action.payload)
    },
    deleteDiet(state, action: PayloadAction<string>) {
      state.diet = [...state.diet.filter((el) => el !== action.payload)]
    },
    discardDiet(state) {
      state.diet = []
    },
    addSearch(state, action: PayloadAction<string>) {
      state.search = action.payload
    },
    discardSearch(state) {
      state.search = undefined
    },
    discardAllFilter(state) {
      state = initialState
    },
  },
})

export default FiltersSlice.reducer
