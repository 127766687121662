import { FC } from 'react'
import { MenuProducts } from '../../../components/Products/CustomProductsLists/MenuProducts'

import {
  ICategory,
  IProduct,
} from '../../../store/reducers/CategoriesReducer/types'
import { CategoryItemContainer, CategoryItemText } from './styled'
import { useOpenProductModal } from 'hooks/useOpenProductModal'
import { useOpenQRMProduct } from 'featureSliced/shared/hooks/useOpenProductModal'

type CategoryItemType = {
  category: ICategory
}

export const CategoryItem: FC<CategoryItemType> = ({ category }) => {
  const openCCProductModal = useOpenProductModal()
  const openQRMProductModal = useOpenQRMProduct()
  const handleProductClick = (product: IProduct, categoryName?: string) => {
    if (process.env.REACT_APP_IS_CLIC === '0') {
      openQRMProductModal(product)
    }
    if (process.env.REACT_APP_IS_CLIC === '1') {
      openCCProductModal(product, categoryName)
    }
  }

  return (
    <CategoryItemContainer
      id={`container_category_${category?.id}`}
      className="list_category__container"
    >
      <CategoryItemText
        id={`category_${category?.id}`}
        className="list_category"
      >
        {category?.name}
      </CategoryItemText>
      <MenuProducts
        products={category?.products}
        onProductClick={(product: IProduct) => {
          handleProductClick(product, category?.name)
        }}
      />
    </CategoryItemContainer>
  )
}
