// import { Auth } from '@aws-amplify/auth'
import { store } from '../../store/index'

// type isAuthT = () => Promise<boolean>

export const isAuth = () => {
  const isLoggedIn = store.getState().auth.isLoggedIn
  return isLoggedIn
  // try {
  //   // TODO: rework isAuth func. store data to redux.
  //   await Auth.currentAuthenticatedUser()
  //   return true
  // } catch (e) {
  //   return false
  // }
}
