import Auth from '@aws-amplify/auth'
import { AuthSlice } from '.'
import { AppDispatch } from '../../index'

export const checkIsLoggedIn = () => async (dispatch: AppDispatch) => {
  try {
    const aaaa = await Auth.currentAuthenticatedUser()
    dispatch(AuthSlice.actions.signIn())
  } catch (e) {
    dispatch(AuthSlice.actions.signOut())
  }
}
