import { FC } from 'react'

import minusSvg from 'assets/minus.svg'
import plusSvg from 'assets/plus.svg'

import {
  AmountHandlerContainer,
  AmountHandlerButton,
  AmountHandlerCounter,
} from './styled'

type AmountHandlerType = {
  currentValue: number
  multiplier?: number
  step?: number
  onDecrease: (value: number) => void
  onIncrease: (value: number) => void
  minValue?: number
  maxValue?: number
  isSmall?: boolean
}

export const AmountHandler: FC<AmountHandlerType> = ({
  currentValue,
  multiplier = 1,
  step = 1,
  minValue = 0,
  maxValue,
  onDecrease,
  onIncrease,
  ...props
}) => {
  const handleDecrease = (e: any) => {
    e.stopPropagation()
    if ((currentValue || currentValue === 0) && currentValue > minValue) {
      onDecrease(
        +(currentValue - step).toFixed(
          step.toString().length > 2 ? step.toString().length - 2 : 0
        )
      )
    }
  }
  const handleIncrease = (e: any) => {
    e.stopPropagation()
    if (
      (currentValue || currentValue === 0) &&
      (!maxValue || currentValue < maxValue)
    ) {
      onIncrease(
        +(currentValue + step).toFixed(
          step.toString().length > 2 ? step.toString().length - 2 : 0
        )
      )
    }
  }
  return (
    <AmountHandlerContainer {...props}>
      <AmountHandlerButton
        disabled={currentValue <= minValue}
        onClick={handleDecrease}
        data-testid="amountHandeler_decreaseButton"
      >
        <img src={minusSvg} alt="-" />
      </AmountHandlerButton>
      <AmountHandlerCounter data-testid="amountHandeler_display">
        {currentValue * multiplier}
      </AmountHandlerCounter>
      <AmountHandlerButton
        onClick={handleIncrease}
        data-testid="amountHandeler_increaseButton"
      >
        <img src={plusSvg} alt="+" />
      </AmountHandlerButton>
    </AmountHandlerContainer>
  )
}
