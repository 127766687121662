import { FC } from 'react'
import { RenderIf } from 'components/RenderIf'
import { useTranslations } from 'hooks/useTranslations'

import { DescriptionP } from './styled'

type DescriptionProps = {
  note?: string
}

export const Description: FC<DescriptionProps> = ({ note }) => {
  const t = useTranslations()
  return (
    <RenderIf condition={!!note}>
      {/* <DescriptionTitle>{t('products.description')}</DescriptionTitle> */}
      <DescriptionP>{note}</DescriptionP>
    </RenderIf>
  )
}
