import { useEffect } from 'react'
import { hideLoading } from 'react-redux-loading-bar'
import { Button } from '../../components/Button'
import { ModalsSlice } from '../../store/reducers/ModalsReducer'
import { useMyDispatch } from '../../hooks/useMyDispatch'
import { useTranslations } from '../../hooks/useTranslations'
import history from '../../utils/history'

import box from '../../assets/boxImage.svg'
import {
  NetworkErrorContainer,
  NetworkErrorTitle,
  NetworkErrorSubtitle,
  NetworkErrorTip,
} from './styled'
import { getNavLink } from 'featureSliced/shared/utils/getNavLink'
import { useSearchParams } from 'react-router-dom'

export const NetworkError = () => {
  const t = useTranslations()
  const dispatch = useMyDispatch()
  const [searchParams] = useSearchParams()
  const { closeAllModals } = ModalsSlice.actions

  useEffect(() => {
    dispatch(hideLoading())
    dispatch(closeAllModals())
  }, [])

  return (
    <NetworkErrorContainer>
      <NetworkErrorTitle>{t('errors.commonErrorMessage')}</NetworkErrorTitle>
      <NetworkErrorSubtitle>
        {t('errors.internetErrorMessage')}
      </NetworkErrorSubtitle>
      <img src={box} alt="Error" />
      <NetworkErrorTip>
        Please check your internet settings and try again
        {t('errors.internetWarning')}
      </NetworkErrorTip>
      <Button
        buttonType="common"
        onClick={() => history.replace(getNavLink(searchParams, ''))}
      >
        {t('buttons.refresh')}
      </Button>
    </NetworkErrorContainer>
  )
}

export default NetworkError
