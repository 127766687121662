export const initialState: IToast[] = []
type ToastType = 'error' | 'warning' | 'default'

export interface IToastPayload {
  message: string
  type: ToastType
  delay: number
}

export interface IToast extends IToastPayload {
  id: number
}

export const DefaultToast: IToastPayload = {
  message: '',
  type: 'default',
  delay: 3500,
}
