import styled from 'styled-components'

export const PaymentProductsContainer = styled.div`
  overflow-y: scroll;
  padding: 0 3vw;
  height: max-content;
  flex-grow: 1;
`

export const TableDiv = styled.div`
  /* height: 50px; */
  width: 100%;
  text-align: center;

  color: ${({ theme }) => theme.FontColor};

  font-weight: 600;
  font-size: 18px !important;
  line-height: 18px;
  padding-top: 16px;
`

// SubFooter

type SubFooterProps = {
  isSlow?: boolean
  text?: string
}

export const SubFooterComponent = styled('div')<SubFooterProps>`
  padding: 0 20px;
  color: ${({ theme }) => theme.FontColor};
  background-color: ${({ theme }) => theme.FooterColor};
  border-top: 1px solid #cdcdcd;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  position: relative;
`

export const SubFooterCheckboxContainer = styled.div`
  padding: 5px 15px;
  color: ${({ theme }) => theme.FontColor};
  background-color: ${({ theme }) => theme.FooterColor};
  border-top: 1px solid #cdcdcd;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  * {
    /* font-size: 10px; */
  }
`

export const SignUpButton = styled.p`
  gap: 10px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  margin: 2px 0;
  align-items: center;
`

export const SignUpDecorated = styled.span`
  text-decoration: underline;
`

export const SignUpDescription = styled.p`
  text-align: start;
  font-size: 13px;
  margin: 0 0 5px;
  line-height: 13px;
`
