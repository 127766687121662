import { useEffect, useState } from 'react'
import { Amplify } from '@aws-amplify/core'
import { ToastContainer } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { amplifyConfig } from './aws'

// components
import { Modal } from './components/Modal'
import { SpinnerOverlay } from './components/OverlaySpinner'

// Hooks
import { useStartApp } from './hooks/useStartApp'
import { useMySelector } from './hooks/useMySelector'
import { useHandleOffset } from './hooks/useHandleOffset'

// utils
import { AppRouter } from './AppRouter'

// include Bootstrap and ReactToastify CSS
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { PaymentConfirmation } from 'featureSliced/features/PaymentConfirmation'
import { Toaster } from 'featureSliced/features/Toaster'

Amplify.configure(amplifyConfig)

function App() {
  //
  // Hooks
  const location = useLocation()
  const handleOffset = useHandleOffset()

  // Store
  const { isTranslationsLoaded } = useMySelector((state) => state.translations)
  const { interfaceStyles, isAppInit } = useMySelector((state) => state.app)

  // State
  const [isLoading, setIsLoading] = useState(false)
  const [startApp, initialLoading, loadMenuData] = useStartApp(setIsLoading)

  // useEffects
  useEffect(() => {
    startApp()
    window.addEventListener('scroll', handleOffset)
    return () => window.removeEventListener('scroll', handleOffset)
  }, [])

  useEffect(() => {
    if (
      location.pathname === '/welcome' ||
      location.pathname === '/scanner' ||
      location.pathname === '/network-error'
    ) {
      setIsLoading(false)
    } else {
      setIsLoading(!isAppInit)
    }
  }, [isAppInit, location.pathname])

  return (
    <>
      <ThemeProvider theme={interfaceStyles || {}}>
        <AppRouter
          initialLoading={initialLoading}
          initLoadMenuData={loadMenuData}
        />
        <Modal />
        <Toaster />
        <PaymentConfirmation />
        <SpinnerOverlay isLoading={isLoading || !isTranslationsLoaded} />
        <ToastContainer position="bottom-right" pauseOnHover />
      </ThemeProvider>
    </>
  )
}

export default App
