import { useEffect, useMemo, useState } from 'react'
import Auth from '@aws-amplify/auth'

import { isAuth } from 'utils/isAuth'
import { Header } from 'components/Header'
import { FooterPaymentQRM } from './Footer'
import { RenderIf } from 'components/RenderIf'
import { errorHandler } from 'components/ErrorHandler'
import { clickCollectGET } from 'api/clickCollect/GET'
import { Spinner } from 'featureSliced/entities/Spinner'

import { Cart } from 'featureSliced/widgets/Cart'
import { Tips } from 'featureSliced/widgets/Tips'
import { SubFooter } from 'featureSliced/widgets/Cart/SubFooter'
import { AmountHandler } from 'featureSliced/widgets/AmountHandler'
import { AgeProtectionModal } from 'featureSliced/widgets/AgeProtectionModal'

import { useProductProtection } from 'hooks/useProductProtection'
import { useTranslations } from 'hooks/useTranslations'
import { useMySelector } from 'hooks/useMySelector'
import { useOpenModal } from 'hooks/useOpenModal'
import { usePayment } from 'hooks/usePayment'

import {
  ITableCloudItem,
  ITableCloudItemGroupped,
} from 'store/reducers/QRMenuTableReducer/types'
import { IBucketProduct } from 'store/reducers/QRMenuBucketReducer/types'

import { CardsModal } from './QRMCardsModal'

import { CurrentPageType } from './types'
import { EmptyBlock, PaymentContainer } from './styled'
import { convertToPrice } from 'utils/convertToPrice'
import { getProductTotalPrice } from 'utils/getProductTotalPrice'
import { ICartProduct } from 'store/generalTypes'
import { qrMenuGET } from 'api/qrMenu/GET'

type SplitProductType = {
  uniqueId: string
  count: number
}

export const PaymentQRM = () => {
  // Hooks
  const t = useTranslations()
  const openModal = useOpenModal()
  const { startQRMAfterMealPayment: startPayment } = usePayment()
  const { isProductHighlyProtected, isProductProtected } =
    useProductProtection()

  // Store
  const isUserAuth = useMySelector((state) => state.auth.isLoggedIn)
  const currency = useMySelector((state) => state.app.companyData.Currency)
  const paymentVariant = useMySelector((state) => state.app.menu.paymentVariant)
  const { cloudItems, isTableLoading, modVer, orderGuid, cloudTotal } =
    useMySelector((state) => state.qrMenuTable)
  const isProductsLoaded = useMySelector(
    (state) => state.products.isProductsLoaded
  )

  // State
  const [currentPage, setCurrentPage] = useState<CurrentPageType>('cart')
  const [isAVGConfirmed, setIsAVGConfirmed] = useState<boolean>(false)
  // const [isUserAuth, setIsUserAuth] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [tipsValue, setTipsValue] = useState<number>(0)
  const [isLoyaltyCardExisting, setIsLoyaltyCardExisting] =
    useState<boolean>(false)

  // Split state
  const [splitProducts, setSplitProducts] = useState<SplitProductType[]>([])
  const [currentSelectedProductUniqueId, setCurrentSelectedProductUniqueId] =
    useState<string>()

  // useEffect
  useEffect(() => {
    checkIfLoyaltyCardExists()
  }, [isUserAuth])

  // Consts
  const cloudItemsGroupped: ITableCloudItemGroupped[] = useMemo(() => {
    return cloudItems?.reduce(
      (acc: ITableCloudItemGroupped[], curr: ITableCloudItem) => {
        const sMI = JSON.parse(curr.menuItems || '[]')
          ?.map((sMI: any) => `${sMI.productId}_${sMI.count}`)
          ?.sort()
          ?.toString()

        const sKI = Object.values(JSON.parse(curr.kitchenFreeInfo || '{}'))
          ?.filter(Boolean)
          ?.sort()
          ?.join('')

        const uniqueId = `${curr.productId}_${sKI}_${sMI}`
        const foundIndex = acc.findIndex((item) => item.uniqueId === uniqueId)

        if (foundIndex !== -1) {
          acc[foundIndex] = {
            ...acc?.[foundIndex],
            itemGuids: [
              ...(acc?.[foundIndex]?.itemGuids || []),
              curr?.itemGuid,
            ],
            count: (acc?.[foundIndex]?.count || 0) + (curr?.count || 0),
          }
        } else {
          acc.push({
            ...curr,
            itemGuids: [curr.itemGuid],
            uniqueId: `${curr.productId}_${sKI}_${sMI}`,
          })
        }

        return acc
      },
      []
    )
  }, [cloudItems])

  // Functions
  const handleSplitProductClick = (
    _productId: any,
    _e: any,
    clickedProduct: ICartProduct
  ) => {
    setSplitProducts((prevValue) => {
      if (!prevValue?.find((el) => el.uniqueId === clickedProduct.uniqueId)) {
        setCurrentSelectedProductUniqueId(clickedProduct.uniqueId)
        return [
          ...prevValue,
          {
            uniqueId: clickedProduct.uniqueId || '',
            count: clickedProduct.count,
          },
        ]
      } else {
        setCurrentSelectedProductUniqueId(undefined)
        return prevValue?.filter(
          (el) => el.uniqueId !== clickedProduct.uniqueId
        )
      }
    })
  }

  const getIsProductSelectedForSplit = (product: ITableCloudItem) =>
    !!splitProducts?.find((el) => el.uniqueId === product.uniqueId)?.count

  const getResultPrice = (): number => {
    if (currentPage === 'split' || splitProducts?.length) {
      const total = splitProducts?.reduce((acc, curr) => {
        const cloudItem = cloudItemsGroupped?.find(
          (cItem) => cItem.uniqueId === curr.uniqueId
        )
        if (cloudItem) {
          const productTotal = getProductTotalPrice(
            {
              ...cloudItem,
              count: curr.count,
            },
            currency
          )
          return (acc += productTotal)
        }
        return acc
      }, 0)
      return total
    } else {
      // const total = cloudItemsGroupped?.reduce((acc, curr) => {
      //   const productTotal = getProductTotalPrice(curr, currency)
      //   return (acc += productTotal)
      // }, 0)
      return cloudTotal
    }
  }

  const getResultPriceWithCurrency = () =>
    `${convertToPrice(getResultPrice() + tipsValue)} ${currency || ''}`

  const getProductSplitCount = (product: ITableCloudItem) =>
    splitProducts?.find((el) => el.uniqueId === product.uniqueId)?.count || 0

  const handleSplitCount = (newValue: number) => {
    if (currentSelectedProductUniqueId) {
      setSplitProducts((prevValue) => {
        if (newValue) {
          return prevValue?.map((el) => {
            if (el.uniqueId === currentSelectedProductUniqueId) {
              return { ...el, count: newValue }
            } else {
              return el
            }
          })
        } else {
          return prevValue?.filter(
            (el) => el.uniqueId !== currentSelectedProductUniqueId
          )
        }
      })
    }
  }

  const getIsNextAndPayDisabled = () => {
    return (
      (!isUserAuth && !isAVGConfirmed) ||
      (currentPage === 'split' && !splitProducts?.length)
    )
  }

  const checkIfLoyaltyCardExists = async () => {
    try {
      if (isUserAuth) {
        const res = await clickCollectGET.customerCard()
        setIsLoyaltyCardExisting(!!res?.cardNumber)
      }
    } catch (e) {
      errorHandler(e)
    }
  }

  const openAgeProtectionModal = (
    itemsToPay: ITableCloudItem[] | IBucketProduct[],
    isHighlyProtected = false
  ) => {
    openModal({
      id: 'AGE_PROTECTION_MODAL',
      title: t('common.confirmYourAge'),
      components: [
        ({ closeThisModal }) => (
          <AgeProtectionModal
            isHighlyProtected={isHighlyProtected}
            startPayment={() => {
              startPaymentRequest(itemsToPay)
              closeThisModal()
            }}
          />
        ),
      ],
    })
  }

  const startPaymentProccess = () => {
    const itemsToPay: ITableCloudItem[] | IBucketProduct[] = (function () {
      switch (true) {
        case Boolean(splitProducts?.length):
          return splitProducts?.map((splitP) => {
            const fullItem = cloudItemsGroupped?.find(
              (cloudP) => cloudP.uniqueId === splitP.uniqueId
            )
            if (fullItem) {
              return {
                ...fullItem,
                itemGuids: fullItem.itemGuids.slice(0, splitP.count),
                count: splitP.count,
              }
            }
            return splitP as ITableCloudItem
          })
        default:
          return cloudItems
      }
    })()
    console.log('cloudItemsGroupped:', cloudItemsGroupped)
    console.log('cloudItems:', cloudItems)
    console.log('itemsToPay:', itemsToPay)
    if (
      itemsToPay?.some((product) => isProductHighlyProtected(product.productId))
    ) {
      openAgeProtectionModal(itemsToPay, true)
    } else if (
      itemsToPay?.some((product) => isProductProtected(product.productId))
    ) {
      openAgeProtectionModal(itemsToPay)
    } else {
      startPaymentRequest(itemsToPay)
    }
  }

  const startPaymentRequest = async (
    itemsToPay: ITableCloudItem[] | IBucketProduct[]
  ) => {
    if (isAVGConfirmed || isUserAuth) {
      try {
        setIsLoading(true)
        await qrMenuGET.modVer(modVer, orderGuid)

        try {
          if (!isUserAuth) {
            await startPayment(itemsToPay, tipsValue)
          }
          if (isUserAuth) {
            const currentUserInfo = await Auth.currentUserInfo()
            const attributes = currentUserInfo?.attributes
            if (
              (!attributes || !attributes?.['custom:active_card_token']) &&
              !isLoyaltyCardExisting
            ) {
              await startPayment(itemsToPay, tipsValue)
            } else {
              setIsLoading(false)
              openModal({
                id: 'PAYMENT_CARDS_MODAL',
                title: 'Choose payment variant',
                components: [
                  ({ closeThisModal }) => (
                    <CardsModal
                      isLoyaltyCardExisting={isLoyaltyCardExisting}
                      itemsToPay={itemsToPay}
                      tipsValue={tipsValue}
                      userCardData={attributes?.['custom:active_card_token']}
                      closeModal={closeThisModal}
                    />
                  ),
                ],
              })
            }
          }
        } catch (eee) {
          errorHandler(eee)
        }
      } catch (e) {
        console.log('mod ver error:', e)
        // TODO: location.reload is temporary and fastest solution. get rid of it
        window.location.reload() // mod ver is wrong. we need to reload to get refreshed data
      } finally {
        setIsLoading(false)
      }
    }
  }

  // Components
  const renderAmountHandler = () => (
    <AmountHandler
      isSmall
      currentValue={
        splitProducts?.find(
          (el) => el.uniqueId === currentSelectedProductUniqueId
        )?.count || 0
      }
      minValue={0}
      maxValue={
        cloudItemsGroupped?.find(
          (el) => el.uniqueId === currentSelectedProductUniqueId
        )?.count
      }
      onDecrease={handleSplitCount}
      onIncrease={handleSplitCount}
    />
  )

  return (
    <>
      <PaymentContainer>
        <Header />
        <EmptyBlock />
        <RenderIf condition={isTableLoading || !isProductsLoaded}>
          <Spinner type="transparent" />
        </RenderIf>

        {/* CART */}
        <RenderIf condition={currentPage === 'cart'}>
          <Cart
            products={isProductsLoaded ? cloudItemsGroupped || cloudItems : []}
          />
        </RenderIf>

        {/* SPLIT */}
        <RenderIf condition={currentPage === 'split'}>
          <Cart
            isSplit
            products={cloudItemsGroupped}
            getAltCount={getProductSplitCount}
            handleProductClick={handleSplitProductClick}
            getIsProductSelected={getIsProductSelectedForSplit}
          />
        </RenderIf>

        {/* TIPS */}
        <RenderIf condition={currentPage === 'tips'}>
          <Tips
            // total={cloudTotal}
            total={getResultPrice()}
            tipsValue={tipsValue}
            onChange={setTipsValue}
          />
        </RenderIf>
        {/* SUBFOOTER */}
        <SubFooter
          isAVGConfirmed={isAVGConfirmed}
          setIsAVGConfirmed={setIsAVGConfirmed}
          //
          isUserAuth={isUserAuth}
        />
        <EmptyBlock height="120px" />
      </PaymentContainer>

      {/* FOOTER */}
      <FooterPaymentQRM
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        renderAmountHandler={renderAmountHandler}
        getIsNextAndPayDisabled={getIsNextAndPayDisabled}
        tipsValue={tipsValue}
        startPaymentProccess={startPaymentProccess}
        isLoading={isLoading}
        getResultPrice={getResultPriceWithCurrency}
      />
    </>
  )
}
