import { FC, useEffect, useState } from 'react'
import { useTranslations } from 'hooks/useTranslations'
import { RenderIf } from 'components/RenderIf'
import { Button } from 'featureSliced/entities/Button'
import { Radio, RadioContainer } from 'components/Radio/styled'
import { CCProvider } from 'utils/getCCProviderIcon'
import { usePayment } from 'hooks/usePayment'
import { ITableCloudItem } from 'store/reducers/QRMenuTableReducer/types'
import { IBucketProduct } from 'store/reducers/QRMenuBucketReducer/types'
import { CheckBox } from 'featureSliced/entities/CheckBox'

type CardsModalProps = {
  itemsToPay: ITableCloudItem[] | IBucketProduct[]
  isLoyaltyCardExisting: boolean
  tipsValue: number
  userCardData: any
  closeModal: () => void
}

export const CardsModal: FC<CardsModalProps> = ({
  itemsToPay,
  isLoyaltyCardExisting,
  tipsValue = 0,
  userCardData,
  closeModal,
}) => {
  const t = useTranslations()
  const { startQRMAfterMealPayment: paymentRequest } = usePayment()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [value, setValue] = useState<'0' | '1' | '2'>('1')
  const [updateActiveCard, setUpdateActiveCard] = useState(false)

  useEffect(() => {
    if (!userCardData) {
      setValue('0')
    }
  }, [])

  const changeValue = (e: any) => {
    if (e.target.value !== '2') {
      setUpdateActiveCard(false)
    }
    setValue(e.target.value)
  }

  const handlePayClick = async () => {
    setIsLoading(true)
    await paymentRequest(
      itemsToPay,
      tipsValue,
      value === '1', // useSavedCard
      value === '0', // useCustomerCard
      updateActiveCard && value === '2' // updateActiveCard (if NEW is selected)
    )
    setIsLoading(false)
    closeModal()
  }

  return (
    <div>
      <RenderIf condition={isLoyaltyCardExisting}>
        <RadioContainer>
          <Radio
            type="radio"
            id="customer"
            name="customerCard"
            value={0}
            checked={value === '0'}
            onChange={changeValue}
          />
          <label htmlFor="customer">{t('order.useCustomerCard')}</label>
        </RadioContainer>
      </RenderIf>
      <RenderIf condition={userCardData}>
        <RadioContainer>
          <Radio
            type="radio"
            id="saved"
            name="card"
            value={1}
            checked={value === '1'}
            onChange={changeValue}
          />
          <label htmlFor="saved">
            <CCProvider
              cardMask={JSON.parse(userCardData || null)?.masked}
              brandName={JSON.parse(userCardData || null)?.brand?.PaymentMethod}
            />{' '}
            {JSON.parse(userCardData || null)?.masked}
          </label>
        </RadioContainer>
      </RenderIf>
      <RadioContainer>
        <Radio
          type="radio"
          id="useNew"
          name="card"
          value={2}
          checked={value === '2'}
          onChange={changeValue}
        />
        {/* TODO: translations */}
        <label htmlFor="useNew">Use new card</label>
      </RadioContainer>

      <CheckBox
        isChecked={updateActiveCard}
        onClick={setUpdateActiveCard}
        label={t('selfService.newCardAsDefault')}
        isDisabled={value !== '2'}
      />
      <br />

      <Button
        buttonType="common"
        width="100%"
        onClick={handlePayClick}
        isLoading={isLoading}
      >
        {t('cover.pay')}
      </Button>
    </div>
  )
}
